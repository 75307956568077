import { FC } from "react";

const UkLangIconSVG: FC = () => {
    return (
        <svg className="rounded-xl me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_301_1200)">
                <path d="M0 0H20V20.0002H0V0Z" fill="white" />
                <path d="M11.25 0H8.75V8.12497H0V11.875H8.75V19.9999H11.25V11.875H20V8.12497H11.25V0Z" fill="#D80027" />
                <path
                    d="M15.3821 13.478L19.9999 17.3262V13.478H15.3821ZM12.1738 13.478L19.9999 19.9998V18.1556L14.3869 13.478H12.1738ZM17.9153 19.9998L12.1738 15.2147V19.9998H17.9153Z"
                    fill="#0052B4"
                />
                <path d="M12.1738 13.478L19.9999 19.9997V18.1556L14.3869 13.478H12.1738Z" fill="white" />
                <path d="M12.1738 13.478L19.9999 19.9997V18.1556L14.3869 13.478H12.1738Z" fill="#D80027" />
                <path
                    d="M3.52895 13.478L0 16.4189V13.478H3.52895ZM7.82609 14.3074V19.9998H0.995742L7.82609 14.3074Z"
                    fill="#0052B4"
                />
                <path d="M5.61301 13.478L0 18.1556V19.9997L7.82609 13.478H5.61301Z" fill="#D80027" />
                <path
                    d="M4.61777 6.52173L0 2.67352V6.52173H4.61777ZM7.82609 6.52173L0 0V1.84418L5.61301 6.52173H7.82609ZM2.08461 0L7.82609 4.785V0H2.08461Z"
                    fill="#0052B4"
                />
                <path d="M7.82609 6.52173L0 0V1.84418L5.61301 6.52173H7.82609Z" fill="white" />
                <path d="M7.82609 6.52173L0 0V1.84418L5.61301 6.52173H7.82609Z" fill="#D80027" />
                <path
                    d="M16.471 6.52178L19.9999 3.58096V6.52178H16.471ZM12.1738 5.69245V0H19.0042L12.1738 5.69245Z"
                    fill="#0052B4"
                />
                <path d="M14.3869 6.52173L19.9999 1.84418V0L12.1738 6.52173H14.3869Z" fill="#D80027" />
            </g>
            <defs>
                <clipPath id="clip0_301_1200">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UkLangIconSVG;
