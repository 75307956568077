import { FormikHelpers, FormikProps, useFormik } from "formik";
import { FieldMetaData, Fields } from "../types/SchemaFactory";
import { AnyObject, ObjectSchema } from "yup";
import { SelectProps } from "../types/SelectProps";

interface UseCustomFormikProps<T> {
    fields: Fields<T>;
    schema: ObjectSchema<AnyObject>;
    initial: T;
    onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
}

interface UseCustomFormik<T> {
    form: FormikProps<T>;
}

function useCustomFormik<FieldsType>({
    fields,
    schema,
    initial,
    onSubmit,
}: UseCustomFormikProps<FieldsType>): UseCustomFormik<FieldsType> {
    const initialValues = Object.entries(fields).reduce((acc, [key, value]) => {
        (acc as any)[key as keyof FieldsType] = initial
            ? initial[key as keyof FieldsType]
            : (value as FieldMetaData).type === "select"
            ? ((value as FieldMetaData).initial as SelectProps).value
            : (value as FieldMetaData).initial;
        return acc;
    }, {} as FieldsType);

    const form = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit,
        enableReinitialize: true,
    });

    return { form };
}

export default useCustomFormik;
