export enum ImpactOnServicesEnum {
    NO_DATA,
    NO_IMPACT_AT_ALL,
    NO_IMPACT_ON_SERVICES,
    MINIMAL_IMPACT_ON_NON_CRITICAL_SERVICES,
    MINIMAL_IMPACT_ON_CRITICAL_SERVICES,
    SIGNIFICANT_IMPACT_ON_NON_CRITICAL_SERVICES,
    SIGNIFICANT_IMPACT_ON_CRITICAL_SERVICES,
    LOSS_OF_AVAILABILITY_OF_NON_CRITICAL_SERVICES,
    LOSS_OF_AVAILABILITY_OF_CRITICAL_SERVICES
}
