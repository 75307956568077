import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

interface CustomToggleProps {
    labelKey: string;
    name?: string;
    value: boolean;
    errorKey?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomToggle: FC<CustomToggleProps> = ({ labelKey, name, value, errorKey, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-center h-[40px]">
            {labelKey && (
                <label className="mr-2 text-[16px] font-semibold text-gray-500" htmlFor={labelKey}>
                    {t(labelKey)}
                </label>
            )}
            <label className="mt-[4px] inline-flex items-center cursor-pointer">
                <input
                    name={name || ""}
                    type="checkbox"
                    value=""
                    className="fixed none sr-only peer"
                    checked={value}
                    onChange={onChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <p className={`mt-1 ml-3 text-sm font-normal text-red-500`}>
                {errorKey ? t(errorKey) : name ? "\u00A0" : ""}
            </p>
        </div>
    );
};

export default CustomToggle;
