import { FC } from "react";
import { CriticalityLevelEnum, criticalityLevelColoring } from "../../../domain/enums/CriticalityLevelEnum";
import { useTranslation } from "react-i18next";
import { ExtendedField } from "../../../domain/models/dto/IncidentDTO";
import { isNil } from "lodash";
import { IncidentFieldsEnum } from "../../../domain/enums/IncidentFieldsEnum";
import { getExtendedSorted, getExtendedValue } from "../../../tools/ExtendedFieldTools";

interface ExtendedCriticalityLevelComponentProps {
    field: CriticalityLevelEnum | ExtendedField<CriticalityLevelEnum>;
}

const ExtendedCriticalityLevelComponent: FC<ExtendedCriticalityLevelComponentProps> = ({ field }) => {
    const { t } = useTranslation();
    const value = getExtendedValue(field);

    return (
        <td className={"px-2 py-2 text-center border" + (getExtendedSorted(field) ? " text-gray-300" : "")}>
            {isNil(value) ? (
                <></>
            ) : (
                <div
                    style={{
                        backgroundColor: criticalityLevelColoring[value].background,
                        color: criticalityLevelColoring[value].text,
                    }}
                    className="rounded-lg p-1 ml-auto mr-auto truncate max-w-[95px]"
                    title={value + " - " + t(`incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.level.${value}`)}
                >
                    {value + " - " + t(`incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.level.${value}`)}
                </div>
            )}
        </td>
    );
};

export default ExtendedCriticalityLevelComponent;
