export enum AffectedSystemTypeEnum {
    NO_DATA,
    OTHER,
    NETWORK_EQUIPMENT,
    FIREWALLS,
    WORKSTATIONS,
    DOMAIN_NAME_SERVERS,
    WEB_SERVERS,
    APPLICATION_SERVERS,
    MAIL_SERVERS,
    DATABASE_SERVERS
}
