import { FC, useEffect, useState } from "react";
import Header from "../header/Header";
import IncidentsTable from "./IncidentsTable";
import { incidentServiceFactory } from "../../services/IncidentServiceImpl";
import {
    ExtendedIncident,
    ExtendedIncidentCollection,
    Incident,
    IncidentCollection,
} from "../../domain/models/Incident";
import { PaginationEnum } from "../../domain/enums/common/PaginationEnum";
import Pagination from "../shared/Pagination";
import { NullableCollection, ResponseMessageObj, defaultResponseMessageObj } from "../../types/ApiResults";
import { DEFAULT_PAGE } from "../../domain/constants";
import { SelectValue } from "../../types/SelectProps";
import Toast from "../shared/Toast";
import { ResponseMessage } from "../../domain/enums/common/ResponseMessageEnum";
import { TabsEnum } from "../../domain/enums/TabsEnum";
import { IncidentDTO } from "../../domain/models/dto/IncidentDTO";
import { v4 as uuidv4 } from "uuid";
import LoadingPanel from "../shared/loading-panel/LoadingPanel";
import IncidentsTableManagement from "./IncidentsTableManagement";
import IncidentsClassifiersPage from "./IncidentsClassifiersPage";

interface IncidentsPageProps {}

const incidentService = incidentServiceFactory();

const IncidentsPage: FC<IncidentsPageProps> = () => {
    const [incidents, setIncidents] = useState<IncidentCollection | ExtendedIncidentCollection>(NullableCollection);
    const [selectedIncident, setSelectedIncident] = useState<Incident>(null);

    const [reloadPage, setReloadPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [currentLimit, setCurrentLimit] = useState(PaginationEnum.SHOW_20);
    const [messageObj, setMessageObj] = useState<ResponseMessageObj>(defaultResponseMessageObj);
    const [tabName, setTabName] = useState(TabsEnum.INCIDENTS);
    const [extendedEnable, setExtendedEnable] = useState(false);

    useEffect(() => {
        (async () => {
            if (tabName !== TabsEnum.INCIDENTS && tabName !== TabsEnum.QUEUE) {
                return;
            }

            setLoading(true);
            const result = await incidentService[
                tabName === TabsEnum.INCIDENTS ? "getIncidents" : extendedEnable ? "getExtendedQueue" : "getQueue"
            ](currentPage, currentLimit);
            if (result) {
                setIncidents(result);
                resetSelectedIncident();
                setReloadPage(false);
                setMessageObj({
                    id: uuidv4(),
                    messageType: ResponseMessage.SUCCESS,
                    messageKey: "incident.messages.tableUpdated",
                });
            } else {
                setMessageObj({
                    id: uuidv4(),
                    messageType: ResponseMessage.DANGER,
                    messageKey: "errors.common",
                });
            }
            setLoading(false);
        })();
    }, [currentLimit, currentPage, reloadPage, tabName]);

    const createOrUpdateIncident = async (values: IncidentDTO) => {
        const result = { status: false };
        if (selectedIncident?.id) {
            selectedIncident.assignData(values);
            result.status = !!(await incidentService.updateIncident(selectedIncident))?.id;
        } else {
            result.status = !!(await incidentService.createIncident(values))?.id;
        }
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: `incident.messages.${selectedIncident?.id ? "incidentUpdated" : "incidentCreated"}`,
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const deleteIncident = async (incident: Incident | ExtendedIncident) => {
        const result = await incidentService.deleteIncident(incident?.id);
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "incident.messages.incidentDeleted",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const deleteAllIncidents = async () => {
        const result = await incidentService.deleteAllIncidents();
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "incident.messages.incidentDeleted",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const limitHandler = (value: SelectValue) => {
        setCurrentLimit(value as number);
    };

    const pageHandler = (value: number) => {
        setCurrentPage(value);
    };

    const resetSelectedIncident = () => {
        setSelectedIncident(null);
    };

    const resetMessageObj = () => {
        setMessageObj(defaultResponseMessageObj);
    };

    const successEmulationCallback = () => {
        setReloadPage(true);
    };

    const updateQueueCallback = () => {
        setTabName(TabsEnum.QUEUE);
        setReloadPage(true);
    };

    const errorEmulationCallback = () => {
        setMessageObj({
            id: uuidv4(),
            messageType: ResponseMessage.DANGER,
            messageKey: "errors.common",
        });
    };

    const successCSVImportCallback = () => {
        setReloadPage(true);
    };

    const errorCSVImportCallback = () => {
        setMessageObj({
            id: uuidv4(),
            messageType: ResponseMessage.DANGER,
            messageKey: "errors.common",
        });
    };

    return (
        <>
            <LoadingPanel isVisible={loading} />
            <Header />
            <Toast messageObj={messageObj} resetMessageObj={resetMessageObj} />
            <IncidentsTableManagement
                successCSVImportCallback={successCSVImportCallback}
                errorCSVImportCallback={errorCSVImportCallback}
                resetSelectedIncident={resetSelectedIncident}
                selectedIncident={selectedIncident}
                createOrUpdateIncident={createOrUpdateIncident}
                setLoading={setLoading}
                successEmulationCallback={successEmulationCallback}
                errorEmulationCallback={errorEmulationCallback}
                updateQueueCallback={updateQueueCallback}
                deleteAllIncidents={deleteAllIncidents}
                limitHandler={limitHandler}
                tabName={tabName}
                setTabName={setTabName}
                extendedEnable={extendedEnable}
                setExtendedEnable={setExtendedEnable}
            />
            {tabName !== TabsEnum.CLASSIFIERS ? (
                <>
                    <IncidentsTable
                        isQueue={tabName === TabsEnum.QUEUE}
                        isExtendedQueue={tabName === TabsEnum.QUEUE && extendedEnable}
                        incidents={incidents.rows}
                        updateIncidentHandler={setSelectedIncident}
                        deleteIncidentHandler={deleteIncident}
                    />
                    <Pagination currentPage={currentPage} totalPages={incidents.totalPages} pageHandler={pageHandler} />
                </>
            ) : (
                <IncidentsClassifiersPage />
            )}
        </>
    );
};

export default IncidentsPage;
