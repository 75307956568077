import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../shared/modals/BaseModal";
import DeleteTrashIconSVG from "../shared/svg/DeleteTrashIconSVG";

interface IncidentDeletionOneRecordButtonProps {
    incidentOrder: number;
    deleteHandler: () => void;
}

const IncidentDeletionOneRecordButton: FC<IncidentDeletionOneRecordButtonProps> = ({
    incidentOrder,
    deleteHandler,
}) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            <BaseModal
                buttonElement={
                    <div
                        onClick={() => setIsVisible(true)}
                        className="flex items-center justify-center bg-red-600 hover:bg-red-700 focus:ring-4 rounded-lg h-7 w-8"
                    >
                        <DeleteTrashIconSVG iconColor="white" />
                    </div>
                }
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                title={t("incident.incidentsOneRecordDeletion.title") + ` ${incidentOrder}`}
                confirmHandler={deleteHandler}
                additionalConfirmButtonClassName="bg-red-600 hover:bg-red-700 focus:ring-4"
            >
                <div>{t("incident.incidentsOneRecordDeletion.text")}</div>
            </BaseModal>
        </>
    );
};

export default IncidentDeletionOneRecordButton;
