export enum SectorEnum {
    NO_DATA,
    OTHER,
    MASS_MEDIA,
    ENTERPRISES_OF_THE_APPROPRIATE_OWNERSHIP_FORM,
    TRANSPORT_SECTOR,
    IT_SECTOR,
    LOCAL_GOVERNMENT_BODIES,
    ELECTRONIC_COMMUNICATIONS_SERVICES,
    OTHER_CRITICAL_ORGANIZATIONS,
    ENERGY_SECTOR,
    FINANCIAL_SECTOR,
    GOVERNMENT_AUTHORITIES,
    SECURITY_AND_DEFENSE_SECTOR
}
