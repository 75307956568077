import { DEFAULT_PAGE, DEFAULT_ROWS } from "../domain/constants";
import { ResponseMessage } from "../domain/enums/common/ResponseMessageEnum";

export interface CollectionResponse<T> {
    rows: T[];
    totalRows: number;
    totalPages: number;
}

export interface RemoveResponse {
    status: boolean;
}

export const NullableCollection: CollectionResponse<any> = {
    rows: [],
    totalRows: DEFAULT_ROWS,
    totalPages: DEFAULT_PAGE,
};

export interface ResponseMessageObj {
    id: string;
    messageType: ResponseMessage;
    messageKey: string;
}

export const defaultResponseMessageObj: ResponseMessageObj = {
    id: null,
    messageType: null,
    messageKey: null,
};
