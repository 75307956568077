import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cookie from "react-cookies";
import { translations } from "../translations";
import { BehaviorSubject } from "rxjs";
import { Langs } from "../domain/enums/common/LangsEnum";
import localStorageServiceFactory from "./LocalStorageServiceImpl";
import { LanguageService } from "../domain/services/LanguageService";

export const LANGS = [Langs.UA, Langs.EN];
const DEFAULT_LANGUAGE = Langs.UA;

export class LanguageServiceImpl implements LanguageService {
    private constructor() {
        this._currentLang =
            localStorageServiceFactory().getLang() || DEFAULT_LANGUAGE;
    }

    private static _instance: LanguageService;
    private _currentLang: string = DEFAULT_LANGUAGE;
    private _langs: string[] = LANGS;

    static lang$ = new BehaviorSubject(DEFAULT_LANGUAGE);

    public static getInstance(): LanguageService {
        if (!LanguageServiceImpl._instance) {
            LanguageServiceImpl._instance = new LanguageServiceImpl();
        }
        return LanguageServiceImpl._instance;
    }

    initLanguages(): void {
        i18n.use(initReactI18next).init({
            resources: translations,
            lng: this.currentLang || cookie.load("locale"),
            keySeparator: ".",
        });
    }

    get currentLang(): string {
        return this._currentLang;
    }

    get langs(): string[] {
        return this._langs.filter((lang) => lang !== this.currentLang);
    }

    setCurrentLang(value: string): void {
        this._currentLang = value;
        i18n.changeLanguage(this._currentLang);
        localStorageServiceFactory().setLang(value as Langs);
    }

    notifyListeners(lang: Langs): void {
        LanguageServiceImpl.lang$.next(lang);
    }

    isCurrentLang(lang: string): boolean {
        return lang === this.currentLang;
    }

    // getCurrentPhoneNumberLocale(): any {
    //     switch (this.currentLang) {
    //         case Langs.EN:
    //             return require(`react-phone-number-input/locale/en.json`);
    //         case Langs.UA:
    //             return require(`react-phone-number-input/locale/ua.json`);
    //         default:
    //             return require(`react-phone-number-input/locale/en.json`);
    //     }
    // }
}

export default function languageServiceFactory(): LanguageService {
    return LanguageServiceImpl.getInstance();
}
