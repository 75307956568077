import { AffectedSystemTypeEnum } from "../domain/enums/AffectedSystemTypeEnum";
import { ClientsAffectedEnum } from "../domain/enums/ClientsAffectedEnum";
import { CriticalityLevelEnum } from "../domain/enums/CriticalityLevelEnum";
import { ImpactOnServicesEnum } from "../domain/enums/ImpactOnServicesEnum";
import { NetAmountEnum } from "../domain/enums/NetAmountEnum";
import { ResultOfExposureEnum } from "../domain/enums/ResultOfExposureEnum";
import { SectorEnum } from "../domain/enums/SectorEnum";
import { StateLayerEnum } from "../domain/enums/StateLayerEnum";
import { VerificationStatusEnum } from "../domain/enums/VerificationStatusEnum";
import { IncidentDTO } from "../domain/models/dto/IncidentDTO";
import { Incident } from "../domain/models/Incident";
import { getFullDateFormat, getShortDateFormat } from "../tools/DatetimeTools";
import { BaseModelImpl } from "./BaseModelImpl";

export class IncidentModel
    extends BaseModelImpl<IncidentDTO>
    implements Incident
{
    constructor(data?: IncidentDTO) {
        super();
        this.assignData(data);
    }

    id?: string;
    order?: number;
    priority?: number;
    reportReliable: boolean;
    status: VerificationStatusEnum;
    criticalityLevel: CriticalityLevelEnum;
    cvss: number;
    stateLayer: StateLayerEnum;
    sector: SectorEnum;
    loss: number;
    down: number;
    clientsAffected: ClientsAffectedEnum;
    resultOfExposure: ResultOfExposureEnum;
    dateTimeDiscovery: string;
    dateTimeStart: string;
    impactOnServices: ImpactOnServicesEnum;
    netAmount: NetAmountEnum;
    affectedSystemType: AffectedSystemTypeEnum;
    dateTimeRegistration: string;
    dateCreated?: string;
    dateModified?: string;

    get dateTimeDiscoveryShort() {
        return getShortDateFormat(this.dateTimeDiscovery);
    }

    get dateTimeDiscoveryFull() {
        return getFullDateFormat(this.dateTimeDiscovery);
    }

    get dateTimeStartShort() {
        return getShortDateFormat(this.dateTimeStart);
    }

    get dateTimeStartFull() {
        return getFullDateFormat(this.dateTimeStart);
    }

    get dateTimeRegistrationShort() {
        return getShortDateFormat(this.dateTimeRegistration);
    }

    get dateTimeRegistrationFull() {
        return getFullDateFormat(this.dateTimeRegistration);
    }
}
