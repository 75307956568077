import { SelectProps, SelectValue } from "../types/SelectProps";
import { getRandomNumber } from "./RandomTools";

export function getEnumKeys<T extends object>(enumeration: T) {
    return Object.keys(enumeration).filter((key) => isNaN(Number(key)));
}

export function enumToSelectProps<T extends Record<string, SelectValue>>(
    enumeration: T,
    labelKey: string
): SelectProps[] {
    return getEnumKeys(enumeration).map((value) => {
        return {
            labelKey: `${labelKey}.${enumeration[value as keyof T]}`,
            value: enumeration[value as keyof T],
        };
    });
}

export function enumValueToSelectProps(
    value: SelectValue,
    labelKey: string
): SelectProps {
    return {
        labelKey: `${labelKey}.${value}`,
        value,
    };
}

export function getRandomNumberOfEnum<T>(enumObj: T): number {
    const enumValues = Object.keys(enumObj)
        .filter((key) => !isNaN(Number(key)))
        .map((key) => enumObj[key as unknown as keyof T]);

    const randomIndex = getRandomNumber(enumValues.length);
    return randomIndex;
}
