import { environment } from "./environments/environment";

export const apiURL = environment.API_URL;

export const useNgrok = environment.USE_NGROK.toLowerCase() === "true";

export const maximumEmulationIntervalSec =
    +environment.MAXIMUM_EMULATION_INTERVAL_SEC;
export const minimumEmulationIntervalSec =
    +environment.MINIMUM_EMULATION_INTERVAL_SEC;

export const emulationDays = +environment.EMULATION_DAYS;
