import { FC, useState } from "react";
import { DEFAULT_PAGE } from "../../domain/constants";
import { iterPages } from "../../tools/PaginationTools";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    pageHandler: (value: number) => void;
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, pageHandler }) => {
    const pagesNumbers = iterPages(currentPage, totalPages);

    const getPaginationLink = (page: number, index: number) => {
        const isCurrentPage = page === currentPage;
        return (
            <li key={index}>
                {page ? (
                    <a
                        href="#"
                        className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 border ${
                            isCurrentPage ? "text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 z-10" : ""
                        }`}
                        onClick={() => pageHandler(page)}
                        style={{
                            borderTopLeftRadius: index === 0 ? "8px" : "0",
                            borderBottomLeftRadius: index === 0 ? "8px" : "0",
                            borderTopRightRadius: index === pagesNumbers.length - 1 ? "8px" : "0",
                            borderBottomRightRadius: index === pagesNumbers.length - 1 ? "8px" : "0",
                        }}
                    >
                        <div className={isCurrentPage ? "font-bold" : ""}>{page}</div>
                    </a>
                ) : (
                    <div className="flex items-center border justify-center px-4 h-10 leading-tight text-gray-500 bg-white">
                        ...
                    </div>
                )}
            </li>
        );
    };

    return (
        <nav className="flex justify-center mt-4">
            <ul className="mb-2 ml-2 inline-flex text-base h-10 border border-gray-300 rounded-lg overflow-hidden">
                {pagesNumbers.map((page, index) => getPaginationLink(page, index))}
            </ul>
        </nav>
    );
};
export default Pagination;
