import { ChangeEvent, FC } from "react";
import { InputType } from "../../../types/InputType";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

interface CustomInputProps {
    type: InputType;
    labelKey?: string;
    value?: string | number;
    name?: string;
    placeholderKey?: string;
    errorKey?: string;
    disabled?: boolean;
    className?: string;
    decimalScale?: number;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: FC<CustomInputProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col">
            {props.labelKey && (
                <label className="block mb-2 text-lg font-semibold text-[#344054]" htmlFor={props.labelKey}>
                    {t(props.labelKey)}
                </label>
            )}
            {props.type === "number" ? (
                <NumericFormat
                    value={props.value}
                    decimalScale={props.decimalScale || 1}
                    type="text"
                    className={`px-3 py-2 text-base text-[#344054] bg-white border-2 border-[#d0d5dd] shadow-sm rounded-lg placeholder-[#667085] ${props.className}`}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    id={props.labelKey}
                    name={props.name || ""}
                    placeholder={t(props.placeholderKey)}
                />
            ) : (
                <input
                    type={props.type}
                    id={props.labelKey}
                    value={props.value}
                    name={props.name || ""}
                    placeholder={t(props.placeholderKey)}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    className={`px-3 py-2 text-base text-[#344054] bg-white border-2 border-[#d0d5dd] shadow-sm rounded-lg placeholder-[#667085] ${props.className}`}
                />
            )}
            <p className={`mt-1 ml-3 text-sm font-normal text-red-500`}>
                {props.errorKey ? t(props.errorKey) : props.name ? "\u00A0" : ""}
            </p>
        </div>
    );
};

export default CustomInput;
