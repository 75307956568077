import { ExtendedIncidentMapper } from "../domain/mappers/ExtendedIncidentMapper";
import { Mapper } from "../domain/mappers/Mapper";
import { ExtendedIncident, Incident } from "../domain/models/Incident";
import {
    ExtendedField,
    ExtendedIncidentDTO,
    IncidentDTO,
} from "../domain/models/dto/IncidentDTO";
import { ExtendedIncidentModel } from "../models/ExtendedIncidentModel";
import { IncidentModel } from "../models/IncidentModel";
import { getIsoStringDateFormat } from "../tools/DatetimeTools";
import { getExtendedValue } from "../tools/ExtendedFieldTools";
import { SimpleMapper } from "./SimpleMapper";

class ExtendedIncidentMapperImpl extends SimpleMapper<
    ExtendedIncident,
    ExtendedIncidentDTO
> {
    protected entityConstructor = ExtendedIncidentModel;

    protected toDTOFields: string[] = [];

    protected fromDTOFields: string[] = [
        "id",
        "order",
        "priority",
        "dateCreated",
        "dateModified",
        "reportReliable",
        "status",
        "criticalityLevel",
        "cvss",
        "stateLayer",
        "sector",
        "loss",
        "down",
        "clientsAffected",
        "resultOfExposure",
        "dateTimeDiscovery",
        "dateTimeStart",
        "impactOnServices",
        "netAmount",
        "affectedSystemType",
        "dateTimeRegistration",
    ];

    extendedFieldFromDTO<T>(field: T | ExtendedField<T>): ExtendedField<T> {
        return {
            value: getExtendedValue(field),
            sorted: !!(field as ExtendedField<T>)?.sorted,
        };
    }

    fromDTO(dto: ExtendedIncidentDTO): ExtendedIncident {
        const model = super.fromDTO(dto);
        model.criticalityLevel = this.extendedFieldFromDTO(
            dto.criticalityLevel
        );
        model.cvss = this.extendedFieldFromDTO(dto.cvss);
        model.stateLayer = this.extendedFieldFromDTO(dto.stateLayer);
        model.sector = this.extendedFieldFromDTO(dto.sector);
        model.loss = this.extendedFieldFromDTO(dto.loss);
        model.down = this.extendedFieldFromDTO(dto.down);
        model.clientsAffected = this.extendedFieldFromDTO(dto.clientsAffected);
        model.resultOfExposure = this.extendedFieldFromDTO(
            dto.resultOfExposure
        );
        model.impactOnServices = this.extendedFieldFromDTO(
            dto.impactOnServices
        );
        model.netAmount = this.extendedFieldFromDTO(dto.netAmount);
        model.affectedSystemType = this.extendedFieldFromDTO(
            dto.affectedSystemType
        );
        model.dateTimeDiscovery = getIsoStringDateFormat(dto.dateTimeDiscovery);
        model.dateTimeStart = getIsoStringDateFormat(dto.dateTimeStart);
        model.dateTimeRegistration = getIsoStringDateFormat(
            dto.dateTimeRegistration
        );
        return model;
    }

    extendedToCommon(extended: ExtendedIncident): Incident {
        const dto = {
            id: extended.id,
            order: extended.order,
            priority: extended.priority,
            reportReliable: extended.reportReliable,
            status: extended.status,
            dateTimeDiscovery: extended.dateTimeDiscovery,
            dateTimeRegistration: extended.dateTimeRegistration,
            dateTimeStart: extended.dateTimeStart,
            dateCreated: extended.dateCreated,
            dateModified: extended.dateModified,

            criticalityLevel: extended.criticalityLevel?.value,
            cvss: extended.cvss?.value,
            stateLayer: extended.stateLayer?.value,
            sector: extended.sector?.value,
            loss: extended.loss?.value,
            down: extended.down?.value,
            clientsAffected: extended.clientsAffected?.value,
            resultOfExposure: extended.resultOfExposure?.value,
            impactOnServices: extended.impactOnServices?.value,
            netAmount: extended.netAmount?.value,
            affectedSystemType: extended.affectedSystemType?.value,
        };
        return new IncidentModel(dto);
    }
}

export default function extendedIncidentMapperFactory(): ExtendedIncidentMapper<
    ExtendedIncident,
    ExtendedIncidentDTO
> {
    return new ExtendedIncidentMapperImpl();
}
