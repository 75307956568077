import { FC } from "react";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { Langs } from "../../domain/enums/common/LangsEnum";
import UkLangIconSVG from "./svg/UkLangIconSVG";
import UaLangIconSVG from "./svg/UaLangIconSVG";

interface CurrentLangIconProps {
    lang?: string;
}

const CurrentLangIcon: FC<CurrentLangIconProps> = (props) => {
    const langService: LanguageService = languageServiceFactory();
    switch (props.lang ? props.lang : langService.currentLang) {
        case Langs.EN:
            return <UkLangIconSVG />;
        case Langs.UA:
            return <UaLangIconSVG />;
        default:
            return <UaLangIconSVG />;
    }
};
export default CurrentLangIcon;
