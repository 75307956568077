import { FC } from "react";
import { CVSSColoring, CVSSEnum, getCVSSEnumKey } from "../../../domain/enums/CVSSEnum";
import { ExtendedField } from "../../../domain/models/dto/IncidentDTO";
import { isNil } from "lodash";
import { getExtendedSorted, getExtendedValue } from "../../../tools/ExtendedFieldTools";

interface ExtendedCVSSComponentProps {
    field: CVSSEnum | ExtendedField<CVSSEnum>;
}

const ExtendedCVSSComponent: FC<ExtendedCVSSComponentProps> = ({ field }) => {
    const value = getExtendedValue(field);
    const CVSSEnumValue = getCVSSEnumKey(value);
    return (
        <td className={"px-2 py-2 text-center border" + (getExtendedSorted(field) ? " text-gray-300" : "")}>
            {isNil(value) ? (
                <></>
            ) : (
                <div
                    style={{
                        backgroundColor: CVSSColoring[CVSSEnumValue].background,
                        color: CVSSColoring[CVSSEnumValue].text,
                    }}
                    className="rounded-lg p-1 text-center"
                >
                    {value}
                </div>
            )}
        </td>
    );
};

export default ExtendedCVSSComponent;
