import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import languageServiceFactory, { LanguageServiceImpl } from "./services/LanguageServiceImpl";

const container = document.getElementById("root");
const root = createRoot(container!);

const languageService = languageServiceFactory();
languageService.initLanguages();

LanguageServiceImpl.lang$.subscribe((lang) => {
    if (languageService.isCurrentLang(lang)) {
        Root();
    }
});

function Root() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <App />
            </AuthProvider>
        </BrowserRouter>
    );
}

root.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
