import { RefObject, useEffect } from "react";

/**
    Call your callback if clicked on outside of HTMLElement
*/
export function useClickOutOfElement<Element extends HTMLElement>(
    targetRef: RefObject<Element>,
    callback: () => void,
    excludedRefs: RefObject<Element>[] = [],
) {
    useEffect(() => {
        if (!callback) {
            return;
        }
        const handleClickOutside = (event: MouseEvent) => {
            if (excludedRefs.length) {
                for (const ref of excludedRefs) {
                    if (ref.current && ref.current.contains(event.target as Node)) {
                        return;
                    }
                }
            }
            if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
                callback();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [targetRef, excludedRefs, callback]);
}
