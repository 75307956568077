import { Mapper } from "../domain/mappers/Mapper";
import { IncidentDTO } from "../domain/models/dto/IncidentDTO";
import { Incident } from "../domain/models/Incident";
import { IncidentModel } from "../models/IncidentModel";
import { getIsoStringDateFormat } from "../tools/DatetimeTools";
import { SimpleMapper } from "./SimpleMapper";

class IncidentMapper extends SimpleMapper<Incident, IncidentDTO> {
    protected entityConstructor = IncidentModel;

    protected toDTOFields: string[] = [
        "reportReliable",
        "status",
        "criticalityLevel",
        "cvss",
        "stateLayer",
        "sector",
        "loss",
        "down",
        "clientsAffected",
        "resultOfExposure",
        "dateTimeDiscovery",
        "dateTimeStart",
        "impactOnServices",
        "netAmount",
        "affectedSystemType",
        "dateTimeRegistration",
    ];

    protected fromDTOFields: string[] = [
        "id",
        "order",
        "priority",
        "dateCreated",
        "dateModified",
        ...this.toDTOFields,
    ];

    fromDTO(dto: IncidentDTO): Incident {
        const model = super.fromDTO(dto);
        model.dateTimeDiscovery = getIsoStringDateFormat(dto.dateTimeDiscovery);
        model.dateTimeStart = getIsoStringDateFormat(dto.dateTimeStart);
        model.dateTimeRegistration = getIsoStringDateFormat(
            dto.dateTimeRegistration
        );
        return model;
    }
}

export default function incidentMapperFactory(): Mapper<Incident, IncidentDTO> {
    return new IncidentMapper();
}
