import { FC, useRef, useState } from "react";
import DropdownArrow from "../svg/DropdownArrowSVG";
import { useTranslation } from "react-i18next";
import { SelectProps, SelectValue } from "../../../types/SelectProps";
import { useClickOutOfElement } from "../../../custom-hooks/base-hooks/useClickOutOfElement";

interface CustomDropdownProps {
    defaultValue?: SelectProps;
    data: SelectProps[];
    dropDownCallback: (value: SelectValue) => void;
    labelKey?: string;
    wrapperClassName?: string;
    dropdownHeightClassName?: string;
    usePrefix?: boolean;
}

const CustomDropdown: FC<CustomDropdownProps> = ({
    defaultValue,
    data,
    dropDownCallback,
    labelKey,
    wrapperClassName,
    dropdownHeightClassName,
    usePrefix,
}) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [currentOption, setCurrentOption] = useState<SelectProps>(defaultValue);
    const dropDownRef = useRef();

    useClickOutOfElement(dropDownRef, () => setIsVisible(false));

    const dropDownHandler = (option: SelectProps) => {
        setCurrentOption(option);
        dropDownCallback(option.value);
        setIsVisible(false);
    };

    return (
        <div className={`relative ${wrapperClassName}`}>
            {labelKey && (
                <label className="block mb-2 text-lg font-semibold text-[#344054]" htmlFor={labelKey}>
                    {t(labelKey)}
                </label>
            )}
            <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="text-gray-500 bg-white border-2 border-gray-300 font-medium rounded-lg text-sm px-5 py-[8px] text-center inline-flex items-center"
                type="button"
                onClick={() => setIsVisible(!isVisible)}
            >
                {(usePrefix ? `${currentOption.value} - ` : "") + t(currentOption?.labelKey)} <DropdownArrow />
            </button>

            {/* <!-- Dropdown menu --> */}
            <div
                ref={dropDownRef}
                id="dropdown"
                className={`absolute top-18 z-10 ${
                    isVisible ? "visible" : "hidden"
                } bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
            >
                <ul
                    className={`py-2 text-sm text-gray-700 ${dropdownHeightClassName} overflow-auto`}
                    aria-labelledby="dropdownDefaultButton"
                >
                    {data.map((option) => (
                        <li onClick={() => dropDownHandler(option)} key={`li-custom-dropdown-${option.value}`}>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                                {(usePrefix ? `${option.value} - ` : "") + t(option?.labelKey)}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
export default CustomDropdown;
