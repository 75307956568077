export enum CriticalityLevelEnum {
    NOT_CRITICAL,
    LOW,
    MEDIUM,
    HIGH,
    CRITICAL,
    EXTRAORDINARY,
}

export const criticalityLevelColoring = {
    [CriticalityLevelEnum.NOT_CRITICAL]: {
        background: "#ffffff",
        text: "#000000",
    },
    [CriticalityLevelEnum.LOW]: {
        background: "#00af50",
        text: "#000000",
    },
    [CriticalityLevelEnum.MEDIUM]: {
        background: "#ffff66",
        text: "#000000",
    },
    [CriticalityLevelEnum.HIGH]: {
        background: "#fe9932",
        text: "#000000",
    },
    [CriticalityLevelEnum.CRITICAL]: {
        background: "#c00000",
        text: "#ffffff",
    },
    [CriticalityLevelEnum.EXTRAORDINARY]: {
        background: "#000000",
        text: "#ffffff",
    },
} as const;
