import { SettingsDTO } from "../domain/models/dto/SettingsDTO";
import { SettingsService } from "../domain/services/SettingsService";
import { BaseServiceImpl } from "./BaseServiceImpl";

class SettingsServiceImpl extends BaseServiceImpl implements SettingsService {
    async getSettings(): Promise<SettingsDTO> {
        try {
            return this.get("/api/v1/settings/");
        } catch (err) {
            console.log("SettingsServiceImpl.getSettings");
            console.log(err);
        }
    }

    async updateSettings(dto: SettingsDTO): Promise<SettingsDTO> {
        try {
            return this.patch("/api/v1/settings/", dto);
        } catch (err) {
            console.log("SettingsServiceImpl.updateSettings");
            console.log(err);
        }
    }
}

export default function settingsServiceFactory(): SettingsService {
    return new SettingsServiceImpl();
}
