import { get } from "lodash";
import { ExtendedField } from "../domain/models/dto/IncidentDTO";

export function getExtendedValue<T>(field: T | ExtendedField<T>): T {
    const key = field && Object.keys(field).find((item) => item === "value");
    return key ? get(field, key) : field;
}

export function getExtendedSorted<T>(field: T | ExtendedField<T>): boolean {
    return get(field as ExtendedField<T>, "sorted", false);
}
