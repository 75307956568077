import { BaseService } from "../domain/services/BaseService";
import * as _ from "lodash";
import {
    deleteRequest,
    get,
    patch,
    post,
    sendFile,
} from "../tools/RequestTools";
import { apiURL } from "../Config";
import { CollectionResponse, RemoveResponse } from "../types/ApiResults";
import { DEFAULT_PAGE, DEFAULT_ROWS } from "../domain/constants";

const postfix = "/"; // TODO :: fix it in backend.
export class BaseServiceImpl implements BaseService {
    private getData<T>(obj: any, defaultValue?: T): T {
        return _.get(obj, "data", defaultValue);
    }

    private getCollectionData<T>(
        obj: any,
        defaultValue?: T[]
    ): CollectionResponse<T> {
        return {
            rows: this.getData<T[]>(obj, defaultValue),
            totalPages: _.get(obj, "totalPages", DEFAULT_PAGE),
            totalRows: _.get(obj, "totalRows", DEFAULT_ROWS),
        };
    }

    async get<T>(path: string, defaultValue?: any): Promise<T> {
        return this.getData<T>(await get(apiURL + path), defaultValue);
    }

    async getCollection<T>(
        path: string,
        defaultValue?: any[]
    ): Promise<CollectionResponse<T>> {
        return this.getCollectionData<T>(
            await get(apiURL + path),
            defaultValue
        );
    }

    async post<T>(path: string, data: any, defaultValue?: any): Promise<T> {
        return this.getData<T>(
            await post(apiURL + path + postfix, data),
            defaultValue
        );
    }

    async patch<T>(path: string, data: any, defaultValue?: any): Promise<T> {
        return this.getData<T>(await patch(apiURL + path, data), defaultValue);
    }

    async delete(path: string): Promise<RemoveResponse> {
        return { status: !!(await deleteRequest(apiURL + path)) };
    }

    async sendFile<T>(file: File, url: string, defaultValue?: any): Promise<T> {
        return this.getData<T>(
            await sendFile(file, apiURL + url),
            defaultValue
        );
    }
}
