import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CriticalityLevelEnum } from "../../domain/enums/CriticalityLevelEnum";
import { CVSSEnum } from "../../domain/enums/CVSSEnum";
import { StateLayerEnum } from "../../domain/enums/StateLayerEnum";
import { SectorEnum } from "../../domain/enums/SectorEnum";
import { VerificationStatusEnum } from "../../domain/enums/VerificationStatusEnum";
import { ClientsAffectedEnum } from "../../domain/enums/ClientsAffectedEnum";
import { ResultOfExposureEnum } from "../../domain/enums/ResultOfExposureEnum";
import { ImpactOnServicesEnum } from "../../domain/enums/ImpactOnServicesEnum";
import { NetAmountEnum } from "../../domain/enums/NetAmountEnum";
import { AffectedSystemTypeEnum } from "../../domain/enums/AffectedSystemTypeEnum";

interface IncidentsClassifiersPageProps {}

const IncidentsClassifiersPage: FC<IncidentsClassifiersPageProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="max-w-[95%] flex mx-auto">
            <div className="w-full h-[calc(100vh-138px)] bg-gray-100 rounded-xl overflow-auto shadow-md">
                <table className="w-full text-left text-gray-500">
                    <thead className="sticky top-0 text-[16px] text-gray-700 bg-gray-300">
                        <tr>
                            <th className="px-4 py-2 group">{t("incident.classifiers.classifierName")}</th>
                            <th className="px-4 py-2 group">{t("incident.classifiers.classifierDefinition")}</th>
                            <th className="px-4 py-2 group">{t("incident.classifiers.classifierDescription")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.general")}
                            </th>
                        </tr>
                        <tr id="STATUS" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.STATUS.definition")}</td>
                            <td className="px-4 py-1">{t("incident.STATUS.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>
                                    {`${VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM} —
                                        ${t(
                                            `incident.STATUS.status.${VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM}`
                                        )}`}
                                </p>
                                <p className="mt-2">
                                    {`${VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM} —
                                        ${t(
                                            `incident.STATUS.status.${VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM}`
                                        )}`}
                                </p>
                                <p className="mt-2">
                                    {`${VerificationStatusEnum.PROCESSED_BY_CERT} —
                                        ${t(`incident.STATUS.status.${VerificationStatusEnum.PROCESSED_BY_CERT}`)}`}
                                </p>
                                <p className="mt-2">
                                    {`${VerificationStatusEnum.COMPLETED} —
                                        ${t(`incident.STATUS.status.${VerificationStatusEnum.COMPLETED}`)}`}
                                </p>
                                <p className="mt-2">
                                    {`${VerificationStatusEnum.IN_PROCESS} —
                                        ${t(`incident.STATUS.status.${VerificationStatusEnum.IN_PROCESS}`)}`}
                                </p>
                                <p className="mt-2">
                                    {`${VerificationStatusEnum.IS_PRIMARY} —
                                        ${t(`incident.STATUS.status.${VerificationStatusEnum.IS_PRIMARY}`)}`}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.category")}
                            </th>
                        </tr>
                        <tr id="CRY" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.CRY.definition")}</td>
                            <td className="px-4 py-1">{t("incident.CRY.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>
                                    {`${CriticalityLevelEnum.EXTRAORDINARY} — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#fff] bg-[#000000]">
                                        {t(`incident.CRY.level.${CriticalityLevelEnum.EXTRAORDINARY}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`${CriticalityLevelEnum.CRITICAL} — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#fff] bg-[#c00000]">
                                        {t(`incident.CRY.level.${CriticalityLevelEnum.CRITICAL}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`${CriticalityLevelEnum.HIGH} — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#fe9932]">
                                        {t(`incident.CRY.level.${CriticalityLevelEnum.HIGH}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`${CriticalityLevelEnum.MEDIUM} — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#ffff66]">
                                        {t(`incident.CRY.level.${CriticalityLevelEnum.MEDIUM}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`${CriticalityLevelEnum.LOW} — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#00af50]">
                                        {t(`incident.CRY.level.${CriticalityLevelEnum.LOW}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`${CriticalityLevelEnum.NOT_CRITICAL} — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#ffffff]">
                                        {t(`incident.CRY.level.${CriticalityLevelEnum.NOT_CRITICAL}`)}
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr id="CVSS" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.CVSS.definition")}</td>
                            <td className="px-4 py-1">{t("incident.CVSS.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>
                                    {`9.0 - 10.0 — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#fff] bg-[#c00000]">
                                        {t(`incident.CVSS.metric.${CVSSEnum.CRITICAL}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`7.0 - 8.9 — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#fe9932]">
                                        {t(`incident.CVSS.metric.${CVSSEnum.HIGH}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`4.0 - 6.9 — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#ffff66]">
                                        {t(`incident.CVSS.metric.${CVSSEnum.MEDIUM}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`0.1 - 3.9 — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#00af50]">
                                        {t(`incident.CVSS.metric.${CVSSEnum.LOW}`)}
                                    </span>
                                </p>
                                <p className="mt-2">
                                    {`0 — `}{" "}
                                    <span className="rounded-lg p-1 text-center text-[#000] bg-[#ffffff]">
                                        {t(`incident.CVSS.metric.${CVSSEnum.NOT_CRITICAL}`)}
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr id="SL" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.SL.definition")}</td>
                            <td className="px-4 py-1">{t("incident.SL.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${StateLayerEnum.NATIONAL_SECURITY} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.NATIONAL_SECURITY}`
                                )}`}</p>
                                <p className="mt-2">{`${StateLayerEnum.ECONOMIC_STABILITY} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.ECONOMIC_STABILITY}`
                                )}`}</p>
                                <p className="mt-2">{`${StateLayerEnum.FUNCTIONING_OF_THE_GOVERNMENT} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.FUNCTIONING_OF_THE_GOVERNMENT}`
                                )}`}</p>
                                <p className="mt-2">{`${StateLayerEnum.SECURITY_OF_PERSONAL_DATA_OF_CITIZENS} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.SECURITY_OF_PERSONAL_DATA_OF_CITIZENS}`
                                )}`}</p>
                                <p className="mt-2">{`${StateLayerEnum.NATIONAL_IMAGE} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.NATIONAL_IMAGE}`
                                )}`}</p>
                                <p className="mt-2">{`${StateLayerEnum.OTHER} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.OTHER}`
                                )}`}</p>
                                <p className="mt-2">{`${StateLayerEnum.NO_DATA} — ${t(
                                    `incident.SL.subject.${StateLayerEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                        <tr id="SEC" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.SEC.definition")}</td>
                            <td className="px-4 py-1">{t("incident.SEC.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${SectorEnum.SECURITY_AND_DEFENSE_SECTOR} — ${t(
                                    `incident.SEC.sector.${SectorEnum.SECURITY_AND_DEFENSE_SECTOR}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.GOVERNMENT_AUTHORITIES} — ${t(
                                    `incident.SEC.sector.${SectorEnum.GOVERNMENT_AUTHORITIES}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.FINANCIAL_SECTOR} — ${t(
                                    `incident.SEC.sector.${SectorEnum.FINANCIAL_SECTOR}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.ENERGY_SECTOR} — ${t(
                                    `incident.SEC.sector.${SectorEnum.ENERGY_SECTOR}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.OTHER_CRITICAL_ORGANIZATIONS} — ${t(
                                    `incident.SEC.sector.${SectorEnum.OTHER_CRITICAL_ORGANIZATIONS}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.ELECTRONIC_COMMUNICATIONS_SERVICES} — ${t(
                                    `incident.SEC.sector.${SectorEnum.ELECTRONIC_COMMUNICATIONS_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.LOCAL_GOVERNMENT_BODIES} — ${t(
                                    `incident.SEC.sector.${SectorEnum.LOCAL_GOVERNMENT_BODIES}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.IT_SECTOR} — ${t(
                                    `incident.SEC.sector.${SectorEnum.IT_SECTOR}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.TRANSPORT_SECTOR} — ${t(
                                    `incident.SEC.sector.${SectorEnum.TRANSPORT_SECTOR}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.ENTERPRISES_OF_THE_APPROPRIATE_OWNERSHIP_FORM} — ${t(
                                    `incident.SEC.sector.${SectorEnum.ENTERPRISES_OF_THE_APPROPRIATE_OWNERSHIP_FORM}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.MASS_MEDIA} — ${t(
                                    `incident.SEC.sector.${SectorEnum.MASS_MEDIA}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.OTHER} — ${t(
                                    `incident.SEC.sector.${SectorEnum.OTHER}`
                                )}`}</p>
                                <p className="mt-2">{`${SectorEnum.NO_DATA} — ${t(
                                    `incident.SEC.sector.${SectorEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.economical")}
                            </th>
                        </tr>
                        <tr id="LOSS" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.LOSS.definition")}</td>
                            <td className="px-4 py-1">{t("incident.LOSS.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`0 — ${t("incident.LOSS.amount.none")}`}</p>
                                <p>{`${t("incident.LOSS.amount.moreThanZero")} — ${t(
                                    "incident.LOSS.amount.losses"
                                )}`}</p>
                            </td>
                        </tr>
                        <tr id="DOWN" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.DOWN.definition")}</td>
                            <td className="px-4 py-1">{t("incident.DOWN.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`0 — ${t("incident.DOWN.amount.none")}`}</p>
                                <p>{`${t("incident.DOWN.amount.moreThanZero")} — ${t(
                                    "incident.DOWN.amount.downtime"
                                )}`}</p>
                            </td>
                        </tr>
                        <tr id="CA" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.CA.definition")}</td>
                            <td className="px-4 py-1">{t("incident.CA.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${ClientsAffectedEnum.MORE_THAN_1000000} — ${t(
                                    `incident.CA.affected.${ClientsAffectedEnum.MORE_THAN_1000000}`
                                )}`}</p>
                                <p className="mt-2">{`${ClientsAffectedEnum.FROM_100001_TO_1000000} — ${t(
                                    `incident.CA.affected.${ClientsAffectedEnum.FROM_100001_TO_1000000}`
                                )}`}</p>
                                <p className="mt-2">{`${ClientsAffectedEnum.FROM_20001_TO_100000} — ${t(
                                    `incident.CA.affected.${ClientsAffectedEnum.FROM_20001_TO_100000}`
                                )}`}</p>
                                <p className="mt-2">{`${ClientsAffectedEnum.FROM_1_TO_20000} — ${t(
                                    `incident.CA.affected.${ClientsAffectedEnum.FROM_1_TO_20000}`
                                )}`}</p>
                                <p className="mt-2">{`${ClientsAffectedEnum.NO_DATA} — ${t(
                                    `incident.CA.affected.${ClientsAffectedEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.informational")}
                            </th>
                        </tr>
                        <tr id="RE" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.RE.definition")}</td>
                            <td className="px-4 py-1">{t("incident.RE.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${ResultOfExposureEnum.DATA_LEAK} — ${t(
                                    `incident.RE.result.${ResultOfExposureEnum.DATA_LEAK}`
                                )}`}</p>
                                <p className="mt-2">{`${ResultOfExposureEnum.SYSTEM_BREACH} — ${t(
                                    `incident.RE.result.${ResultOfExposureEnum.SYSTEM_BREACH}`
                                )}`}</p>
                                <p className="mt-2">{`${ResultOfExposureEnum.LOSS_OF_FUNCTIONALITY_OF_SYSTEMS} — ${t(
                                    `incident.RE.result.${ResultOfExposureEnum.LOSS_OF_FUNCTIONALITY_OF_SYSTEMS}`
                                )}`}</p>
                                <p className="mt-2">{`${ResultOfExposureEnum.OTHER} — ${t(
                                    `incident.RE.result.${ResultOfExposureEnum.OTHER}`
                                )}`}</p>
                                <p className="mt-2">{`${ResultOfExposureEnum.NO_DATA} — ${t(
                                    `incident.RE.result.${ResultOfExposureEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.functional")}
                            </th>
                        </tr>
                        <tr id="DTD" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.DTD.definition")}</td>
                            <td className="px-4 py-1">{t("incident.DTD.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{t("incident.DTD.description")}</p>
                            </td>
                        </tr>
                        <tr id="STD" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.STD.definition")}</td>
                            <td className="px-4 py-1">{t("incident.STD.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{t("incident.STD.description")}</p>
                            </td>
                        </tr>
                        <tr id="IS" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.IS.definition")}</td>
                            <td className="px-4 py-1">{t("incident.IS.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_CRITICAL_SERVICES} — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_CRITICAL_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${
                                    ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_NON_CRITICAL_SERVICES
                                } — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_NON_CRITICAL_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${
                                    ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_CRITICAL_SERVICES
                                } — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_CRITICAL_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${
                                    ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_NON_CRITICAL_SERVICES
                                } — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_NON_CRITICAL_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${ImpactOnServicesEnum.MINIMAL_IMPACT_ON_CRITICAL_SERVICES} — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.MINIMAL_IMPACT_ON_CRITICAL_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${
                                    ImpactOnServicesEnum.MINIMAL_IMPACT_ON_NON_CRITICAL_SERVICES
                                } — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.MINIMAL_IMPACT_ON_NON_CRITICAL_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${ImpactOnServicesEnum.NO_IMPACT_ON_SERVICES} — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.NO_IMPACT_ON_SERVICES}`
                                )}`}</p>
                                <p className="mt-2">{`${ImpactOnServicesEnum.NO_IMPACT_AT_ALL} — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.NO_IMPACT_AT_ALL}`
                                )}`}</p>
                                <p className="mt-2">{`${ImpactOnServicesEnum.NO_DATA} — ${t(
                                    `incident.IS.impact.${ImpactOnServicesEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.recovery")}
                            </th>
                        </tr>
                        <tr id="NET" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.NET.definitionFull")}</td>
                            <td className="px-4 py-1">{t("incident.NET.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${NetAmountEnum.MORE_THAN_100} — ${t(
                                    `incident.NET.compromised.${NetAmountEnum.MORE_THAN_100}`
                                )}`}</p>
                                <p className="mt-2">{`${NetAmountEnum.FROM_50_TO_100} — ${t(
                                    `incident.NET.compromised.${NetAmountEnum.FROM_50_TO_100}`
                                )}`}</p>
                                <p className="mt-2">{`${NetAmountEnum.FROM_10_TO_50} — ${t(
                                    `incident.NET.compromised.${NetAmountEnum.FROM_10_TO_50}`
                                )}`}</p>
                                <p className="mt-2">{`${NetAmountEnum.FROM_1_TO_10} — ${t(
                                    `incident.NET.compromised.${NetAmountEnum.FROM_1_TO_10}`
                                )}`}</p>
                                <p className="mt-2">{`${NetAmountEnum.NO_DATA} — ${t(
                                    `incident.NET.compromised.${NetAmountEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className="text-center">
                                {t("incident.classifiers.classifierCategory.impact")}
                            </th>
                        </tr>
                        <tr id="AST" className="bg-white border-b">
                            <td className="px-4 py-1">{t("incident.AST.definitionFull")}</td>
                            <td className="px-4 py-1">{t("incident.AST.definitionExtended")}</td>
                            <td className="px-2 py-4">
                                <p>{`${AffectedSystemTypeEnum.DATABASE_SERVERS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.DATABASE_SERVERS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.MAIL_SERVERS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.MAIL_SERVERS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.APPLICATION_SERVERS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.APPLICATION_SERVERS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.WEB_SERVERS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.WEB_SERVERS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.DOMAIN_NAME_SERVERS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.DOMAIN_NAME_SERVERS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.WORKSTATIONS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.WORKSTATIONS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.FIREWALLS} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.FIREWALLS}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.NETWORK_EQUIPMENT} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.NETWORK_EQUIPMENT}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.OTHER} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.OTHER}`
                                )}`}</p>
                                <p className="mt-2">{`${AffectedSystemTypeEnum.NO_DATA} — ${t(
                                    `incident.AST.type.${AffectedSystemTypeEnum.NO_DATA}`
                                )}`}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <nav>
                <ul className="w-[325px] bg-gray-100 rounded-xl ml-[10px] overflow-auto pb-4">
                    <li className="bg-gray-300 text-center text-[16px] text-gray-700 py-2 mb-2">
                        <p>{t("incident.classifiers.navigation")}</p>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#STATUS">{t("incident.STATUS.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#CRY">{t("incident.CRY.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#CVSS">{t("incident.CVSS.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#SL">{t("incident.SL.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#SEC">{t("incident.SEC.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#LOSS">{t("incident.LOSS.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#DOWN">{t("incident.DOWN.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#CA">{t("incident.CA.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#RE">{t("incident.RE.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#DTD">{t("incident.DTD.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#STD">{t("incident.STD.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#IS">{t("incident.IS.definition")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#NET">{t("incident.NET.definitionFull")}</a>
                    </li>
                    <li className="px-6 py-2 hover:bg-gray-200 hover:font-bold rounded-xl">
                        <a href="#AST">{t("incident.AST.definitionFull")}</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default IncidentsClassifiersPage;
