import { IncidentDTO } from "../domain/models/dto/IncidentDTO";
import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { IncidentFieldsEnum } from "../domain/enums/IncidentFieldsEnum";
import { enumToSelectProps, enumValueToSelectProps } from "../tools/EnumTools";
import { CriticalityLevelEnum } from "../domain/enums/CriticalityLevelEnum";
import { StateLayerEnum } from "../domain/enums/StateLayerEnum";
import { SectorEnum } from "../domain/enums/SectorEnum";
import { ClientsAffectedEnum } from "../domain/enums/ClientsAffectedEnum";
import { ResultOfExposureEnum } from "../domain/enums/ResultOfExposureEnum";
import { ImpactOnServicesEnum } from "../domain/enums/ImpactOnServicesEnum";
import { NetAmountEnum } from "../domain/enums/NetAmountEnum";
import { AffectedSystemTypeEnum } from "../domain/enums/AffectedSystemTypeEnum";
import { VerificationStatusEnum } from "../domain/enums/VerificationStatusEnum";
import { getIsoStringDateFormat } from "../tools/DatetimeTools";

export const incidentFields: Fields<IncidentDTO> = {
    reportReliable: {
        min: 0,
        max: 1,
        placeholderKey: `incident.${IncidentFieldsEnum.REPORT_RELIABLE}.definitionFull`,
        type: "boolean",
        initial: true,
    },
    status: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.STATUS}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            VerificationStatusEnum.IS_PRIMARY,
            `incident.${IncidentFieldsEnum.STATUS}.status`
        ),
        selectOptions: enumToSelectProps(
            VerificationStatusEnum,
            `incident.${IncidentFieldsEnum.STATUS}.status`
        ),
    },
    criticalityLevel: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            CriticalityLevelEnum.NOT_CRITICAL,
            `incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.level`
        ),
        selectOptions: enumToSelectProps(
            CriticalityLevelEnum,
            `incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.level`
        ),
    },
    cvss: {
        min: 0,
        max: 10,
        placeholderKey: `incident.${IncidentFieldsEnum.CVSS}.definition`,
        type: "number",
        initial: 0,
    },
    stateLayer: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.STATE_LAYER}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            StateLayerEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.STATE_LAYER}.subject`
        ),
        selectOptions: enumToSelectProps(
            StateLayerEnum,
            `incident.${IncidentFieldsEnum.STATE_LAYER}.subject`
        ),
    },
    sector: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.SECTOR}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            SectorEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.SECTOR}.sector`
        ),
        selectOptions: enumToSelectProps(
            SectorEnum,
            `incident.${IncidentFieldsEnum.SECTOR}.sector`
        ),
    },
    loss: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.LOSS}.definition`,
        type: "number",
        initial: 0,
    },
    down: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.DOWN}.definition`,
        type: "number",
        initial: 0,
    },
    clientsAffected: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.CLIENTS_AFFECTED}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            ClientsAffectedEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.CLIENTS_AFFECTED}.affected`
        ),
        selectOptions: enumToSelectProps(
            ClientsAffectedEnum,
            `incident.${IncidentFieldsEnum.CLIENTS_AFFECTED}.affected`
        ),
    },
    resultOfExposure: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.RESULT_OF_EXPOSURE}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            ResultOfExposureEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.RESULT_OF_EXPOSURE}.result`
        ),
        selectOptions: enumToSelectProps(
            ResultOfExposureEnum,
            `incident.${IncidentFieldsEnum.RESULT_OF_EXPOSURE}.result`
        ),
    },
    dateTimeDiscovery: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.DATE_TIME_DISCOVERY}.definition`,
        type: "datetime-local",
        initial: getIsoStringDateFormat(),
    },
    dateTimeStart: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.DATE_TIME_START}.definition`,
        type: "datetime-local",
        initial: getIsoStringDateFormat(),
    },
    impactOnServices: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.IMPACT_ON_SERVICES}.definition`,
        type: "select",
        initial: enumValueToSelectProps(
            ImpactOnServicesEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.IMPACT_ON_SERVICES}.impact`
        ),
        selectOptions: enumToSelectProps(
            ImpactOnServicesEnum,
            `incident.${IncidentFieldsEnum.IMPACT_ON_SERVICES}.impact`
        ),
    },
    netAmount: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.NET_AMOUNT}.definitionFull`,
        type: "select",
        initial: enumValueToSelectProps(
            NetAmountEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.NET_AMOUNT}.compromised`
        ),
        selectOptions: enumToSelectProps(
            NetAmountEnum,
            `incident.${IncidentFieldsEnum.NET_AMOUNT}.compromised`
        ),
    },
    affectedSystemType: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE}.definitionFull`,
        type: "select",
        initial: enumValueToSelectProps(
            AffectedSystemTypeEnum.NO_DATA,
            `incident.${IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE}.type`
        ),
        selectOptions: enumToSelectProps(
            AffectedSystemTypeEnum,
            `incident.${IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE}.type`
        ),
    },
    dateTimeRegistration: {
        min: 0,
        max: 100,
        placeholderKey: `incident.${IncidentFieldsEnum.DATE_TIME_REGISTRATION}.definitionFull`,
        type: "datetime-local",
        initial: getIsoStringDateFormat(),
    },
};

export const incidentSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, incidentFields);
};
