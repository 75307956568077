import { FC, Fragment } from "react";
import { ExtendedIncident, Incident } from "../../domain/models/Incident";
import { useTranslation } from "react-i18next";
import { IncidentFieldsEnum } from "../../domain/enums/IncidentFieldsEnum";
import IncidentsTableRow from "./incidents-table-row/IncidentsTableRow";
import DeleteTrashIconSVG from "../shared/svg/DeleteTrashIconSVG";
import EditPencilIconSVG from "../shared/svg/EditPencilIconSVG";

interface IncidentsTableProps {
    isQueue: boolean;
    isExtendedQueue: boolean;
    incidents: Incident[] | ExtendedIncident[];
    updateIncidentHandler: (incident: Incident) => void;
    deleteIncidentHandler: (incident: Incident | ExtendedIncident) => void;
}

const IncidentsTable: FC<IncidentsTableProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="w-[95%] h-[calc(100vh-198px)] ml-auto mr-auto relative overflow-auto shadow-md rounded-xl">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="sticky top-0 text-[14px] font-extralight leading-[16px] text-gray-700 bg-gray-300">
                        <tr className="text-center px-2 py-3 group relative">
                            {Object.values(IncidentFieldsEnum).map((value, index) => {
                                if (
                                    value === IncidentFieldsEnum.ID ||
                                    (value === IncidentFieldsEnum.PRIORITY && !props.isQueue)
                                ) {
                                    return <Fragment key={`th-incidents-table-${index}`}></Fragment>;
                                }
                                return (
                                    <th
                                        key={`th-incidents-table-${value}`}
                                        className="px-2 py-3 group border-x border-y-0"
                                    >
                                        {t(`incident.${value}.definition`)}
                                    </th>
                                );
                            })}
                            <th className="px-2 py-3 group border-x border-y-0">
                                <DeleteTrashIconSVG iconColor="black" />
                            </th>
                            <th className="px-2 py-3 group border-x border-y-0">
                                <div className="ml-[8px]">
                                    <EditPencilIconSVG iconColor="black" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.incidents.map((incident) => (
                            <IncidentsTableRow
                                key={`tr-incidents-table-${incident.id}`}
                                incident={incident}
                                isExtendedQueue={props.isExtendedQueue}
                                updateIncidentHandler={props.updateIncidentHandler}
                                deleteIncidentHandler={props.deleteIncidentHandler}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default IncidentsTable;
