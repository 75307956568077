import { FC } from "react";
import CSVImportInput from "../shared/form-inputs/CSVImportInput";
import { useTranslation } from "react-i18next";
import { incidentServiceFactory } from "../../services/IncidentServiceImpl";

interface IncidentsCSVImportButtonProps {
    successCallback: () => void;
    errorCallback: () => void;
    setLoading: (value: boolean) => void;
}

const incidentService = incidentServiceFactory();

const IncidentsCSVImportButton: FC<IncidentsCSVImportButtonProps> = ({
    successCallback,
    errorCallback,
    setLoading,
}) => {
    const { t } = useTranslation();

    const importHandler = async (value: File) => {
        try {
            setLoading(true);
            const result = await incidentService.createIncidentsFromCSV(value);
            successCallback();
        } catch {
            errorCallback();
        }
        setLoading(false);
    };

    return <CSVImportInput title={t("incident.csv.importCSVFile")} setFileCallback={importHandler} />;
};

export default IncidentsCSVImportButton;
