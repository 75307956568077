export enum CVSSEnum {
    NOT_CRITICAL,
    LOW,
    MEDIUM,
    HIGH,
    CRITICAL,
}

export const CVSSColoring = {
    [CVSSEnum.NOT_CRITICAL]: {
        background: "#ffffff",
        text: "#000000",
    },
    [CVSSEnum.LOW]: {
        background: "#00af50",
        text: "#000000",
    },
    [CVSSEnum.MEDIUM]: {
        background: "#ffff66",
        text: "#000000",
    },
    [CVSSEnum.HIGH]: {
        background: "#fe9932",
        text: "#000000",
    },
    [CVSSEnum.CRITICAL]: {
        background: "#c00000",
        text: "#ffffff",
    },
} as const;

export function getCVSSEnumKey(cvssMetric: number): CVSSEnum {
    if (cvssMetric < 0.1) {
        return CVSSEnum.NOT_CRITICAL;
    } else if (cvssMetric >= 0.1 && cvssMetric <= 3.9) {
        return CVSSEnum.LOW;
    } else if (cvssMetric >= 4.0 && cvssMetric <= 6.9) {
        return CVSSEnum.MEDIUM;
    } else if (cvssMetric >= 7.0 && cvssMetric <= 8.9) {
        return CVSSEnum.HIGH;
    } else if (cvssMetric >= 9.0) {
        return CVSSEnum.CRITICAL;
    } else {
        return null;
    }
}
