import { apiURL } from "../Config";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { UserService } from "../domain/services/UserService";
import userMapperFactory from "../mappers/UserMapper";
import { post } from "../tools/RequestTools";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class UserServiceImpl extends BaseServiceImpl implements UserService {
    static _instance: UserService;
    currentUser?: User;

    private constructor() {
        super();
    }

    static getInstance(): UserService {
        if (!UserServiceImpl._instance) {
            UserServiceImpl._instance = new UserServiceImpl();
        }
        return UserServiceImpl._instance;
    }

    async getUser(): Promise<User> {
        return this.currentUser;
    }

    async login(email: string, password: string): Promise<User> {
        if (!email || !password) {
            return;
        }
        const mapper = userMapperFactory();
        this.currentUser = mapper.fromDTO(
            await this.post<UserDTO>("/api/v1/login", { email, password })
        );
        return this.currentUser;
    }
}

export function userServiceFactory(): UserService {
    return UserServiceImpl.getInstance();
}
