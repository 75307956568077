import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { BaseModelImpl } from "./BaseModelImpl";

export default class UserModel extends BaseModelImpl<UserDTO> implements User {
    constructor(data?: UserDTO) {
        super();
        this.assignData(data);
    }

    firstName: string = "";
    lastName: string = "";

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`.trim();
    }
}
