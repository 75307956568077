import { PAGINATION_NUMBER } from "../domain/constants";

export const iterPages = (
    currentPage: number,
    totalPages: number,
    leftEdge = PAGINATION_NUMBER,
    leftCurrent = PAGINATION_NUMBER,
    rightCurrent = PAGINATION_NUMBER,
    rightEdge = PAGINATION_NUMBER
) => {
    let pages = [];
    let lastPage = 0;

    for (let num = 1; num <= totalPages; num++) {
        if (
            num <= leftEdge ||
            (num > currentPage - leftCurrent - 1 &&
                num < currentPage + rightCurrent) ||
            num > totalPages - rightEdge
        ) {
            if (lastPage + 1 !== num) {
                pages.push(null);
            }
            pages.push(num);
            lastPage = num;
        }
    }

    return pages;
};
