import { Langs } from "../domain/enums/common/LangsEnum";
import { LocalStorageService } from "../domain/services/LocalStorageService";

class LocalStorageServiceImpl implements LocalStorageService {
    private constructor() {}
    private static _instance: LocalStorageService;

    private static ITEMS = {
        lang: "lang",
    };

    public static getInstance(): LocalStorageService {
        if (!LocalStorageServiceImpl._instance) {
            LocalStorageServiceImpl._instance = new LocalStorageServiceImpl();
        }
        return LocalStorageServiceImpl._instance;
    }

    getLang(): Langs {
        return localStorage.getItem(
            LocalStorageServiceImpl.ITEMS.lang
        ) as Langs;
    }

    setLang(lang: Langs): void {
        localStorage.setItem(LocalStorageServiceImpl.ITEMS.lang, lang);
    }
}

export default function localStorageServiceFactory(): LocalStorageService {
    return LocalStorageServiceImpl.getInstance();
}
