import { FC, useState } from "react";
import LanguageSelection from "./LanguageSelection";
import { useTranslation } from "react-i18next";
import { TabsEnum } from "../../domain/enums/TabsEnum";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
    const [pageName, setPageName] = useState(TabsEnum.INCIDENTS);
    const { t } = useTranslation();
    return (
        <>
            <div className="flex items-center justify-between py-2 px-12">
                <div className="min-w-0 flex-1">
                    <h2 className="flex items-center text-5xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
                        CSIPM
                        <span className="text-xl font-semibold leading-5 text-gray-700">
                            &nbsp;- Cyber Security Incidents Prioritization Mechanism
                        </span>
                    </h2>
                </div>
                <LanguageSelection />
            </div>
            {/* <h3 className="block p-6 pl-8 pb-0 text-xl ms-2 font-semibold text-gray-500">
                {pageName === TabsEnum.INCIDENTS ? t("incident.tableName") : t("incident.queueTableName")}
            </h3> */}
        </>
    );
};

export default Header;
