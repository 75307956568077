// Responsiveness
export const MIN_DESKTOP_WIDTH = 744;
export const AVG_DESKTOP_WIDTH = 1024;

// Pagination
export const DEFAULT_PAGE = 1;
export const DEFAULT_ROWS = 0;
export const GET_ALL_RECORDS = -1;
export const PAGINATION_NUMBER = 2;

// Timeouts
export const RESPONSE_MESSAGE_VISIBILITY_MS = 3000;
export const MILLISECONDS_IN_ONE_SECOND = 1000;
