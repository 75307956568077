import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { emulationServiceFactory } from "../../services/EmulationServiceImpl";
import { maximumEmulationIntervalSec } from "../../Config";
import StartIconSVG from "../shared/svg/StartIconSvg";
import PauseIconSVG from "../shared/svg/PauseIconSVG";

const emulationService = emulationServiceFactory();

interface IncidentsEmulationMenuProps {
    successEmulationCallback: () => void;
    errorEmulationCallback: () => void;
}

const IncidentsEmulationMenu: FC<IncidentsEmulationMenuProps> = ({
    successEmulationCallback,
    errorEmulationCallback,
}) => {
    const [startedTime, setStartedTime] = useState<Date>(null);
    const { t } = useTranslation();

    const startHandler = () => {
        setStartedTime(new Date());
        emulationService.startEmulation(maximumEmulationIntervalSec);
    };
    const stopHandler = () => {
        setStartedTime(null);
        emulationService.stopEmulation();
    };
    const isStarted = Boolean(startedTime);

    useEffect(() => {
        emulationService.setEmulationCallbacks(successEmulationCallback, errorEmulationCallback);
    }, []);

    return (
        <div className="flex pr-4 border-r-[1px] border-gray-300">
            <button
                className={`ml-4 text-white h-[40px] bg-blue-700 hover:bg-blue-800 fill-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center max-h-10 ${
                    isStarted ? "bg-gray-300 hover:bg-gray-300 focus:ring-0 cursor-default" : "focus:ring-4"
                }`}
                type="button"
                title={t("incident.incidentsEmulation.emulationStart")}
                onClick={startHandler}
            >
                <StartIconSVG className="w-[15px]" />
            </button>
            <button
                className={`ml-2 text-white h-[40px] bg-blue-700 hover:bg-blue-800 fill-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center max-h-10 ${
                    !isStarted ? "bg-gray-300 hover:bg-gray-300 focus:ring-0 cursor-default" : "focus:ring-4"
                }`}
                type="button"
                title={t("incident.incidentsEmulation.emulationStop")}
                onClick={stopHandler}
            >
                <PauseIconSVG className="w-[15px]" />
            </button>
        </div>
    );
};

export default IncidentsEmulationMenu;
