import { useNgrok } from "../Config";
import { ErrorServiceFactory } from "../services/ErrorServiceImpl";

const errorService = ErrorServiceFactory();

const ngrokHeader = useNgrok
    ? {
          "ngrok-skip-browser-warning": "ERR_NGROK_6024",
      }
    : {};

export async function get(url: string) {
    const response = await fetch(url, {
        method: "GET",
        headers: ngrokHeader,
        // TODO :: that might be not optimal solution. Check other options: omit, same-origin.
        credentials: "same-origin",
    });
    return response.json();
}

const defaultHeaders = { "Content-type": "application/json" };
export async function patch(
    url: string,
    data?: any,
    method: string = "PATCH",
    headers: HeadersInit = defaultHeaders,
    stringify: boolean = true
) {
    const response = await fetch(url, {
        method,
        // TODO :: that might be not optimal solution. Check other options: omit, same-origin.
        credentials: "same-origin",
        headers: { ...headers, ...ngrokHeader },
        body: stringify ? JSON.stringify(data) : data,
    });
    if (!response.ok) {
        await errorService.throwResponseError(response);
    }
    return response.json();
}

export async function post(url: string, data: any) {
    return patch(url, data, "POST");
}

export async function deleteRequest(url: string, data?: any) {
    return patch(url, data, "DELETE");
}

export const sendFile = async (file: File, url: string): Promise<unknown> => {
    const formData = new FormData();
    formData.append("file", file);
    return patch(url, formData, "POST", {}, false);
};
