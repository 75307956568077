import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import settingsServiceFactory from "../../services/SettingServiceImpl";
import CustomToggle from "../shared/form-inputs/CustomToggle";

interface IncidentsQueueSortingMenuProps {
    updateQueueCallback: () => void;
    extendedEnable: boolean;
    setExtendedEnable: (value: boolean) => void;
}

const settingService = settingsServiceFactory();

const IncidentsQueueSortingMenu: FC<IncidentsQueueSortingMenuProps> = ({
    extendedEnable,
    setExtendedEnable,
    updateQueueCallback,
}) => {
    const { t } = useTranslation();

    const changeSortingHandler = async () => {
        setExtendedEnable(!extendedEnable);
        updateQueueCallback();
    };

    useEffect(() => {
        (async () => {
            const settings = await settingService.getSettings();
            setExtendedEnable(settings?.enableExtendedQueueSorting);
        })();
    }, []);

    return (
        <div className="flex items-center justify-center h-[40px] ml-4 border-r-[1px] border-gray-300">
            <CustomToggle
                labelKey={t("incident.queueSorting.extended")}
                value={extendedEnable}
                onChange={changeSortingHandler}
            />
        </div>
    );
};

export default IncidentsQueueSortingMenu;
