import { AffectedSystemTypeEnum } from "./domain/enums/AffectedSystemTypeEnum";
import { ClientsAffectedEnum } from "./domain/enums/ClientsAffectedEnum";
import { CriticalityLevelEnum } from "./domain/enums/CriticalityLevelEnum";
import { ImpactOnServicesEnum } from "./domain/enums/ImpactOnServicesEnum";
import { IncidentFieldsEnum } from "./domain/enums/IncidentFieldsEnum";
import { Langs } from "./domain/enums/common/LangsEnum";
import { NetAmountEnum } from "./domain/enums/NetAmountEnum";
import { ResultOfExposureEnum } from "./domain/enums/ResultOfExposureEnum";
import { SectorEnum } from "./domain/enums/SectorEnum";
import { StateLayerEnum } from "./domain/enums/StateLayerEnum";
import { PaginationEnum } from "./domain/enums/common/PaginationEnum";
import { TabsEnum } from "./domain/enums/TabsEnum";
import { VerificationStatusEnum } from "./domain/enums/VerificationStatusEnum";
import { CVSSEnum } from "./domain/enums/CVSSEnum";

export const translations = {
    ua: {
        translation: {
            languages: {
                [Langs.UA]: "Українська",
                [Langs.EN]: "Англійська",
            },
            pagination: {
                show: "Показувати",
                [PaginationEnum.SHOW_ALL]: "Всі",
                [PaginationEnum.SHOW_10]: "10",
                [PaginationEnum.SHOW_20]: "20",
                [PaginationEnum.SHOW_50]: "50",
                [PaginationEnum.SHOW_100]: "100",
            },
            incident: {
                tableName: "Таблиця всіх кіберінцидентів",
                queueTableName: "Таблиця черги кіберінцидентів",
                incidentsDeletion: {
                    title: "Видалити все",
                    text: "Ця дія видаляє всі записи з бази даних!",
                },
                incidentsOneRecordDeletion: {
                    title: "Видалити запис",
                    text: "Ця дія видаляє запис з бази даних!",
                },
                incidentsEmulation: {
                    emulationStart: "Почати емуляцію",
                    emulationStop: "Зупинити емуляцію",
                },
                queueSorting: {
                    common: "Звичайне сортування",
                    extended: "Розширене сортування",
                },
                csv: {
                    importCSVFile: "Імпортувати CSV файл",
                    importCSVFileShort: "Імпортувати CSV -",
                },
                isReportReliable: {
                    yes: "Так",
                    no: "Ні",
                },
                incidentCreation: {
                    newRecordButton: "Створити запис",
                    newRecordTitle: "Створення нового запису",
                    updateRecordTitle: "Оновлення запису",
                    confirmButton: "Підтвердити",
                    cancelButton: "Скасувати",
                },
                tableActions: {
                    deleteTitle: "Видалення",
                    deleteDefinition: "Видалити запис",
                    editTitle: "Зміна",
                    editDefinition: "Змінити запис",
                },
                messages: {
                    tableUpdated: "Дані таблиці оновлено",
                    incidentCreated: "Інцидент створено",
                    incidentUpdated: "Інцидент оновлено",
                    incidentDeleted: "Інцидент видалено",
                    allIncidentsDeleted: "Всі записи видалено",
                },
                classifiers: {
                    navigation: "Навігація",
                    classifierName: "Назва класифікатору",
                    classifierDefinition: "Значення",
                    classifierDescription: "Опис",
                    classifierCategory: {
                        general: "Загальні",
                        category: "Категорія кіберінциденту/кібератаки",
                        economical: "Економічні наслідки кіберінциденту",
                        informational:
                            "Інформаційні наслідки кіберінциденту/кібератаки",
                        functional:
                            "Функціональні наслідки кіберінциденту/кібератаки",
                        recovery:
                            "Можливості відновлення після кіберінциденту/кібератаки",
                        impact: "Особливості впливу кіберінциденту/кібератаки системи/мережі суб’єкта забезпечення кібербезпеки",
                    },
                },
                [IncidentFieldsEnum.ID]: {
                    definition: "ID",
                },
                [IncidentFieldsEnum.ORDER]: {
                    definition: "№",
                },
                [IncidentFieldsEnum.PRIORITY]: {
                    definition: "Пріоритет в черзі",
                },
                [IncidentFieldsEnum.CRITICALITY_LEVEL]: {
                    definition: "Рівень критичності",
                    definitionExtended:
                        "Запропонована категорія (рівень) критичності кіберінциденту/кібератаки",
                    level: {
                        [CriticalityLevelEnum.NOT_CRITICAL]: "Не критичний",
                        [CriticalityLevelEnum.LOW]: "Низький",
                        [CriticalityLevelEnum.MEDIUM]: "Середній",
                        [CriticalityLevelEnum.HIGH]: "Високий",
                        [CriticalityLevelEnum.CRITICAL]: "Критичний",
                        [CriticalityLevelEnum.EXTRAORDINARY]: "Надзвичайний",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.CVSS]: {
                    definition: "Метрика CVSS",
                    definitionExtended: "Значення метрики за шкалою CVSS",
                    metric: {
                        [CVSSEnum.NOT_CRITICAL]: "Відсутній",
                        [CVSSEnum.LOW]: "Низький",
                        [CVSSEnum.MEDIUM]: "Середній",
                        [CVSSEnum.HIGH]: "Високий",
                        [CVSSEnum.CRITICAL]: "Критичний",
                    },
                },
                [IncidentFieldsEnum.STATE_LAYER]: {
                    definition: "Загроза на державному рівні",
                    definitionExtended: "Рівень загрози для держави",
                    subject: {
                        [StateLayerEnum.NO_DATA]: "Немає даних",
                        [StateLayerEnum.OTHER]: "Інше",
                        [StateLayerEnum.NATIONAL_IMAGE]: "Національний імідж",
                        [StateLayerEnum.SECURITY_OF_PERSONAL_DATA_OF_CITIZENS]:
                            "Безпека персональних даних громадян",
                        [StateLayerEnum.FUNCTIONING_OF_THE_GOVERNMENT]:
                            "Функціонування уряду",
                        [StateLayerEnum.ECONOMIC_STABILITY]:
                            "Сталість економіки",
                        [StateLayerEnum.NATIONAL_SECURITY]:
                            "Національна безпека",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.SECTOR]: {
                    definition: "Сектор",
                    definitionExtended: "Сектор (галузь) атакованого об’єкта",
                    sector: {
                        [SectorEnum.NO_DATA]: "Немає даних",
                        [SectorEnum.OTHER]: "Інше",
                        [SectorEnum.MASS_MEDIA]: "Засоби масової інформації",
                        [SectorEnum.ENTERPRISES_OF_THE_APPROPRIATE_OWNERSHIP_FORM]:
                            "Підприємства та організації відповідної форми власності",
                        [SectorEnum.TRANSPORT_SECTOR]: "Транспортна галузь",
                        [SectorEnum.IT_SECTOR]: "ІТ сектор",
                        [SectorEnum.LOCAL_GOVERNMENT_BODIES]:
                            "Органи місцевого самоврядування",
                        [SectorEnum.ELECTRONIC_COMMUNICATIONS_SERVICES]:
                            "Сфера електронних комунікаційних послуг",
                        [SectorEnum.OTHER_CRITICAL_ORGANIZATIONS]:
                            "Інші критичні організації",
                        [SectorEnum.ENERGY_SECTOR]: "Енергетичний сектор",
                        [SectorEnum.FINANCIAL_SECTOR]: "Фінансовий сектор",
                        [SectorEnum.GOVERNMENT_AUTHORITIES]:
                            "Органи державної влади",
                        [SectorEnum.SECURITY_AND_DEFENSE_SECTOR]:
                            "Сектор безпеки і оборони",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.LOSS]: {
                    definition: "Збитки, грн",
                    definitionExtended:
                        "Збитки в гривнях, завдані кіберінцидентом/кібератакою",
                    amount: {
                        none: "Не оцінювалося або менше 1 тис. грн.",
                        moreThanZero: "Більше ніж 0",
                        losses: "Сума збитків в гривнях",
                    },
                    measurement: ", тис. грн.",
                },
                [IncidentFieldsEnum.DOWN]: {
                    definition: "Час застою, години",
                    definitionExtended:
                        "Час застою в годинах, спричинений кіберінцидентом/кібератакою",
                    amount: {
                        none: "Невідомо або не оцінювалося",
                        moreThanZero: "Більше ніж 0",
                        downtime: "Час простою обладнання у робочих годинах",
                    },
                    measurement: ", год.",
                },
                [IncidentFieldsEnum.CLIENTS_AFFECTED]: {
                    definition: "Постраждалі особи",
                    definitionExtended: "Кількість постраждалих осіб",
                    affected: {
                        [ClientsAffectedEnum.NO_DATA]:
                            "Немає даних або постраждалих",
                        [ClientsAffectedEnum.FROM_1_TO_20000]: "1-20000",
                        [ClientsAffectedEnum.FROM_20001_TO_100000]:
                            "20001-100000",
                        [ClientsAffectedEnum.FROM_100001_TO_1000000]:
                            "100001-1000000",
                        [ClientsAffectedEnum.MORE_THAN_1000000]:
                            "Більше ніж 1000000",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.RESULT_OF_EXPOSURE]: {
                    definition: "Результат впливу",
                    definitionExtended:
                        "Результат впливу кіберінциденту/кібератаки на систему/сервіс (послугу)",
                    result: {
                        [ResultOfExposureEnum.NO_DATA]: "Немає даних",
                        [ResultOfExposureEnum.OTHER]: "Інше",
                        [ResultOfExposureEnum.LOSS_OF_FUNCTIONALITY_OF_SYSTEMS]:
                            "Втрата функціональності систем/сервісів",
                        [ResultOfExposureEnum.SYSTEM_BREACH]:
                            "Злам (компрометація) системи",
                        [ResultOfExposureEnum.DATA_LEAK]: "Витік даних",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.DATE_TIME_DISCOVERY]: {
                    definition: "Момент виявлення інциденту, UTC",
                    definitionExtended:
                        "Дата та час виявлення кіберінциденту/кібератаки в форматі дд.мм.рррр гг:хх:сс",
                    description: "Для перевірки правильності даних",
                },
                [IncidentFieldsEnum.DATE_TIME_START]: {
                    definition: "Момент початку інциденту, UTC",
                    definitionExtended:
                        "Дата та час початку кіберінциденту/кібератаки в форматі дд.мм.рррр гг:хх:сс",
                    description: "Для перевірки правильності даних",
                },
                [IncidentFieldsEnum.IMPACT_ON_SERVICES]: {
                    definition: "Вплив на сервіси/мережі",
                    definitionExtended:
                        "Вплив на функціонування систем/мереж, сервіси (послуги)",
                    impact: {
                        [ImpactOnServicesEnum.NO_DATA]: "Немає даних",
                        [ImpactOnServicesEnum.NO_IMPACT_AT_ALL]: "Немає впливу",
                        [ImpactOnServicesEnum.NO_IMPACT_ON_SERVICES]:
                            "Немає впливу на сервіси (послуги)",
                        [ImpactOnServicesEnum.MINIMAL_IMPACT_ON_NON_CRITICAL_SERVICES]:
                            "Мінімальний вплив на некритичні сервіси (послуги)",
                        [ImpactOnServicesEnum.MINIMAL_IMPACT_ON_CRITICAL_SERVICES]:
                            "Мінімальний вплив на критичні сервіси (послуги)",
                        [ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_NON_CRITICAL_SERVICES]:
                            "Значний вплив на некритичні сервіси (послуги)",
                        [ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_CRITICAL_SERVICES]:
                            "Значний вплив на критичні сервіси (послуги)",
                        [ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_NON_CRITICAL_SERVICES]:
                            "Втрата доступності некритичних сервісів (послуг)",
                        [ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_CRITICAL_SERVICES]:
                            "Втрата доступності критичних сервісів (послуг)",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.NET_AMOUNT]: {
                    definition: "Скомпр-ні системи/мережі",
                    definitionFull: "Скомпроментовані системи/мережі",
                    definitionExtended:
                        "Кількість скомпрометованих систем/мереж",
                    compromised: {
                        [NetAmountEnum.NO_DATA]: "Немає даних",
                        [NetAmountEnum.FROM_1_TO_10]: "1-10",
                        [NetAmountEnum.FROM_10_TO_50]: "10-50",
                        [NetAmountEnum.FROM_50_TO_100]: "50-100",
                        [NetAmountEnum.MORE_THAN_100]: "Більше ніж 100",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE]: {
                    definition: "Тип скомпр-ної системи/мережі",
                    definitionFull: "Тип скомпроментованої системи/мережі",
                    definitionExtended:
                        "Тип скомпрометованої системи/мережі за функціоналом",
                    type: {
                        [AffectedSystemTypeEnum.NO_DATA]: "Немає даних",
                        [AffectedSystemTypeEnum.OTHER]: "Інше",
                        [AffectedSystemTypeEnum.NETWORK_EQUIPMENT]:
                            "Мережеве обладнання",
                        [AffectedSystemTypeEnum.FIREWALLS]: "Брандмауер(и)",
                        [AffectedSystemTypeEnum.WORKSTATIONS]: "Робочі станції",
                        [AffectedSystemTypeEnum.DOMAIN_NAME_SERVERS]:
                            "Сервери доменних імен",
                        [AffectedSystemTypeEnum.WEB_SERVERS]: "Веб-сервер(и)",
                        [AffectedSystemTypeEnum.APPLICATION_SERVERS]:
                            "Сервер(и) застосунків",
                        [AffectedSystemTypeEnum.MAIL_SERVERS]:
                            "Поштовий сервер",
                        [AffectedSystemTypeEnum.DATABASE_SERVERS]:
                            "Сервер(и) баз даних",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.DATE_TIME_REGISTRATION]: {
                    definition: "Момент над-ходження, UTC",
                    definitionFull: "Момент надходження, UTC",
                },
                [IncidentFieldsEnum.REPORT_RELIABLE]: {
                    definition: "Досто-вірність звіту",
                    definitionFull: "Достовірність звіту",
                },
                [IncidentFieldsEnum.STATUS]: {
                    status: {
                        [VerificationStatusEnum.IS_PRIMARY]:
                            "Первинна інформація",
                        [VerificationStatusEnum.IN_PROCESS]: "В обробці",
                        [VerificationStatusEnum.COMPLETED]: "Завершений",
                        [VerificationStatusEnum.PROCESSED_BY_CERT]:
                            "Обробка в CERT",
                        [VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM]:
                            "Передали іншій команді",
                        [VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM]:
                            "Завершений іншою командою",
                    },
                    definition: "Статус",
                    definitionExtended:
                        "Поточний стан, в якому знаходиться інцидент",
                },
            },
            tabs: {
                [TabsEnum.INCIDENTS]: "Інциденти",
                [TabsEnum.QUEUE]: "Черга",
                [TabsEnum.CLASSIFIERS]: "Класифікатори",
            },
            errors: {
                common: "Несподівана помилка",
                validation: {
                    min: "Мінімальне значення ",
                    max: "Максимальне значення ",
                    required: "Обов'язкове поле",
                    invalidOption: "Некоректне значення",
                },
            },
        },
    },

    en: {
        translation: {
            languages: {
                [Langs.UA]: "Ukrainian",
                [Langs.EN]: "English",
            },
            pagination: {
                show: "Show",
                [PaginationEnum.SHOW_ALL]: "All",
                [PaginationEnum.SHOW_10]: "10",
                [PaginationEnum.SHOW_20]: "20",
                [PaginationEnum.SHOW_50]: "50",
                [PaginationEnum.SHOW_100]: "100",
            },
            incident: {
                tableName: "Table of all cyber incidents",
                queueTableName: "Table of the queue of cyber incidents",
                incidentsDeletion: {
                    title: "Delete all",
                    text: "This action deletes all records from the database!",
                },
                incidentsOneRecordDeletion: {
                    title: "Delete a record",
                    text: "This action deletes the record from the database",
                },
                incidentsEmulation: {
                    emulationStart: "Start emulation",
                    emulationStop: "Stop emulation",
                },
                queueSorting: {
                    common: "Common sorting",
                    extended: "Extended sorting",
                },
                csv: {
                    importFile: "Import CSV file",
                    importCSVFileShort: "Import CSV -",
                },
                isReportReliable: {
                    yes: "Yes",
                    no: "No",
                },
                incidentCreation: {
                    newRecordButton: "Create a record",
                    newRecordTitle: "Create a new record",
                    updateRecordTitle: "Update a record",
                    confirmButton: "Confirm",
                    cancelButton: "Cancel",
                },
                tableActions: {
                    deleteTitle: "Delete",
                    deleteDefinition: "Delete a record",
                    editTitle: "Edit",
                    editDefinition: "Edit a record",
                },
                messages: {
                    tableUpdated: "Table data was updated",
                    incidentCreated: "Incident was created",
                    incidentUpdated: "Incident was updated",
                    incidentDeleted: "Incident was deleted",
                    allIncidentsDeleted: "All records was deleted",
                },
                classifiers: {
                    navigation: "Navigation",
                    classifierName: "Classifier name",
                    classifierDefinition: "Definition",
                    classifierDescription: "Description",
                    classifierCategory: {
                        general: "General",
                        category: "Category of cyber incident/cyber attack",
                        economical: "Economic consequences of a cyber incident",
                        informational:
                            "Information consequences of a cyber incident/cyber attack",
                        functional:
                            "Functional consequences of a cyber incident/cyber attack",
                        recovery:
                            "Recovery options after a cyber incident/cyber attack",
                        impact: "Peculiarities of the impact of a cyber incident/cyber attack on the cybersecurity entity's system/network",
                    },
                },
                [IncidentFieldsEnum.ID]: {
                    definition: "ID",
                },
                [IncidentFieldsEnum.ORDER]: {
                    definition: "№",
                },
                [IncidentFieldsEnum.PRIORITY]: {
                    definition: "Priority in queue",
                },
                [IncidentFieldsEnum.CRITICALITY_LEVEL]: {
                    definition: "Criticality Level",
                    definitionExtended:
                        "Proposed category (level) of criticality of a cyber incident/cyber attack",
                    level: {
                        [CriticalityLevelEnum.NOT_CRITICAL]: "Not critical",
                        [CriticalityLevelEnum.LOW]: "Low",
                        [CriticalityLevelEnum.MEDIUM]: "Medium",
                        [CriticalityLevelEnum.HIGH]: "High",
                        [CriticalityLevelEnum.CRITICAL]: "Critical",
                        [CriticalityLevelEnum.EXTRAORDINARY]: "Extraordinary",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.CVSS]: {
                    definition: "CVSS metric value",
                    definitionExtended:
                        "The value of the metric on the CVSS scale",
                    metric: {
                        [CVSSEnum.NOT_CRITICAL]: "None",
                        [CVSSEnum.LOW]: "Low",
                        [CVSSEnum.MEDIUM]: "Medium",
                        [CVSSEnum.HIGH]: "High",
                        [CVSSEnum.CRITICAL]: "Critical",
                    },
                },
                [IncidentFieldsEnum.STATE_LAYER]: {
                    definition: "State level threat",
                    definitionExtended: "Threat at the state level",
                    subject: {
                        [StateLayerEnum.NO_DATA]: "No data",
                        [StateLayerEnum.OTHER]: "Other",
                        [StateLayerEnum.NATIONAL_IMAGE]: "National image",
                        [StateLayerEnum.SECURITY_OF_PERSONAL_DATA_OF_CITIZENS]:
                            "Security of personal data of citizens",
                        [StateLayerEnum.FUNCTIONING_OF_THE_GOVERNMENT]:
                            "Functioning of the government",
                        [StateLayerEnum.ECONOMIC_STABILITY]:
                            "Economic stability",
                        [StateLayerEnum.NATIONAL_SECURITY]: "National security",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.SECTOR]: {
                    definition: "Sector",
                    definitionExtended:
                        "Sector (industry) of the attacked object",
                    sector: {
                        [SectorEnum.NO_DATA]: "No data",
                        [SectorEnum.OTHER]: "Other",
                        [SectorEnum.MASS_MEDIA]: "Mass media",
                        [SectorEnum.ENTERPRISES_OF_THE_APPROPRIATE_OWNERSHIP_FORM]:
                            "Enterprises and organizations of the appropriate ownership form",
                        [SectorEnum.TRANSPORT_SECTOR]: "Transport sector",
                        [SectorEnum.IT_SECTOR]: "IT sector",
                        [SectorEnum.LOCAL_GOVERNMENT_BODIES]:
                            "Local government bodies",
                        [SectorEnum.ELECTRONIC_COMMUNICATIONS_SERVICES]:
                            "Electronic communications services",
                        [SectorEnum.OTHER_CRITICAL_ORGANIZATIONS]:
                            "Other critical organizations",
                        [SectorEnum.ENERGY_SECTOR]: "Energy sector",
                        [SectorEnum.FINANCIAL_SECTOR]: "Financial sector",
                        [SectorEnum.GOVERNMENT_AUTHORITIES]:
                            "Government authorities",
                        [SectorEnum.SECURITY_AND_DEFENSE_SECTOR]:
                            "Security and defense sector",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.LOSS]: {
                    definition: "Losses, UAH",
                    definitionExtended:
                        "Losses in UAH caused by a cyber incident/attack",
                    amount: {
                        none: "Not valued or less than 1 thousand UAH.",
                        moreThanZero: "More than 0",
                        losses: "Amount of losses in UAH",
                    },
                    measurement: " th. UAH",
                },
                [IncidentFieldsEnum.DOWN]: {
                    definition: "Downtime, hours",
                    definitionExtended:
                        "Downtime in hours caused by a cyber incident/attack",
                    amount: {
                        none: "Unknown or not evaluated",
                        moreThanZero: "More than 0",
                        downtime: "Equipment downtime in working hours",
                    },
                    measurement: ", hrs.",
                },
                [IncidentFieldsEnum.CLIENTS_AFFECTED]: {
                    definition: "Clients affected",
                    definitionExtended: "Number of clients affected",
                    affected: {
                        [ClientsAffectedEnum.NO_DATA]:
                            "No data or affected clients",
                        [ClientsAffectedEnum.FROM_1_TO_20000]: "1-20000",
                        [ClientsAffectedEnum.FROM_20001_TO_100000]:
                            "20001-100000",
                        [ClientsAffectedEnum.FROM_100001_TO_1000000]:
                            "100001-1000000",
                        [ClientsAffectedEnum.MORE_THAN_1000000]:
                            "More than 1000000",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.RESULT_OF_EXPOSURE]: {
                    definition: "Result of exposure",
                    definitionExtended:
                        "The result of a cyber incident/cyber attack on a system/service",
                    result: {
                        [ResultOfExposureEnum.NO_DATA]: "No data",
                        [ResultOfExposureEnum.OTHER]: "Other",
                        [ResultOfExposureEnum.LOSS_OF_FUNCTIONALITY_OF_SYSTEMS]:
                            "Loss of functionality of systems/services",
                        [ResultOfExposureEnum.SYSTEM_BREACH]:
                            "System breach (compromise)",
                        [ResultOfExposureEnum.DATA_LEAK]: "Data leak",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.DATE_TIME_DISCOVERY]: {
                    definition: "Discovery moment of the incident, UTC",
                    definitionExtended:
                        "Date and time of detection of a cyber incident/cyber attack in the format dd.mm.yyyy hh:mm:ss",
                    description: "To check if the data is correct",
                },
                [IncidentFieldsEnum.DATE_TIME_START]: {
                    definition: "Start moment of the incident, UTC",
                    definitionExtended:
                        "Date and time of the beginning of the cyber incident/cyber attack in the format dd.mm.yyyy hh:mm:ss",
                    description: "To check if the data is correct",
                },
                [IncidentFieldsEnum.IMPACT_ON_SERVICES]: {
                    definition: "Impact on systems (networks)",
                    definitionExtended:
                        "Impact on the functioning of systems/networks, services",
                    impact: {
                        [ImpactOnServicesEnum.NO_DATA]: "No data",
                        [ImpactOnServicesEnum.NO_IMPACT_AT_ALL]: "No impact",
                        [ImpactOnServicesEnum.NO_IMPACT_ON_SERVICES]:
                            "No impact on services",
                        [ImpactOnServicesEnum.MINIMAL_IMPACT_ON_NON_CRITICAL_SERVICES]:
                            "Minimal impact on non-critical services",
                        [ImpactOnServicesEnum.MINIMAL_IMPACT_ON_CRITICAL_SERVICES]:
                            "Minimal impact on critical services",
                        [ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_NON_CRITICAL_SERVICES]:
                            "Significant impact on non-critical services",
                        [ImpactOnServicesEnum.SIGNIFICANT_IMPACT_ON_CRITICAL_SERVICES]:
                            "Significant impact on critical services",
                        [ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_NON_CRITICAL_SERVICES]:
                            "Loss of availability of non-critical services",
                        [ImpactOnServicesEnum.LOSS_OF_AVAILABILITY_OF_CRITICAL_SERVICES]:
                            "Loss of availability of critical services",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.NET_AMOUNT]: {
                    definition: "Compro-mised systems (networks)",
                    definitionFull: "Compromised systems/networks",
                    definitionExtended:
                        "Number of compromised systems/networks",
                    compromised: {
                        [NetAmountEnum.NO_DATA]: "No data",
                        [NetAmountEnum.FROM_1_TO_10]: "1-10",
                        [NetAmountEnum.FROM_10_TO_50]: "10-50",
                        [NetAmountEnum.FROM_50_TO_100]: "50-100",
                        [NetAmountEnum.MORE_THAN_100]: "More than 100",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE]: {
                    definition: "Affected system type",
                    definitionFull: "Affected system type",
                    definitionExtended:
                        "Type of compromised system/network by functionality",
                    type: {
                        [AffectedSystemTypeEnum.NO_DATA]: "No data",
                        [AffectedSystemTypeEnum.OTHER]: "Other",
                        [AffectedSystemTypeEnum.NETWORK_EQUIPMENT]:
                            "Network equipment",
                        [AffectedSystemTypeEnum.FIREWALLS]: "Firewall(s)",
                        [AffectedSystemTypeEnum.WORKSTATIONS]: "Workstations",
                        [AffectedSystemTypeEnum.DOMAIN_NAME_SERVERS]:
                            "Domain name servers",
                        [AffectedSystemTypeEnum.WEB_SERVERS]: "Web-server(s)",
                        [AffectedSystemTypeEnum.APPLICATION_SERVERS]:
                            "Application server(s)",
                        [AffectedSystemTypeEnum.MAIL_SERVERS]: "Mail server",
                        [AffectedSystemTypeEnum.DATABASE_SERVERS]:
                            "Database server(s)",
                        null: "",
                    },
                },
                [IncidentFieldsEnum.DATE_TIME_REGISTRATION]: {
                    definition: "Registra-tion moment, UTC",
                    definitionFull: "Registration moment, UTC",
                },
                [IncidentFieldsEnum.REPORT_RELIABLE]: {
                    definition: "Report relia-bility",
                    definitionFull: "Report reliability",
                },
                [IncidentFieldsEnum.STATUS]: {
                    status: {
                        [VerificationStatusEnum.IS_PRIMARY]: "Primary info",
                        [VerificationStatusEnum.IN_PROCESS]: "In process",
                        [VerificationStatusEnum.COMPLETED]: "Completed",
                        [VerificationStatusEnum.PROCESSED_BY_CERT]:
                            "Processed by CERT",
                        [VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM]:
                            "Passed to another team",
                        [VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM]:
                            "Resolved by another team",
                    },
                    definition: "Status",
                    definitionExtended: "Current state of the incident",
                },
            },
            tabs: {
                [TabsEnum.INCIDENTS]: "Incidents",
                [TabsEnum.QUEUE]: "Queue",
                [TabsEnum.CLASSIFIERS]: "Classifiers",
            },
            errors: {
                common: "Unexpected error",
                validation: {
                    min: "Minimal value ",
                    max: "Maximum value ",
                    required: "Required field",
                    invalidOption: "Invalid option",
                },
            },
        },
    },
};
