import { TFunction } from "i18next";

export enum Langs {
    UA = "ua",
    EN = "en",
}

export function getCurrentLanguageTranslation(t: TFunction, language: string) {
    return { value: language?.toString(), label: t(language ? `languages.${language}` : "") };
}
