import { FC, useState } from "react";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { getCurrentLanguageTranslation } from "../../domain/enums/common/LangsEnum";
import { useTranslation } from "react-i18next";
import CurrentLangIcon from "../shared/CurrentLangIcon";

interface LangSelectProps {}

const LangSelect: FC<LangSelectProps> = (props) => {
    const { t } = useTranslation();
    const langService: LanguageService = languageServiceFactory();
    const [isSelectLangVisible, setSelectLangVisibility] = useState<boolean>(false);

    const handleChangeLang = (lang: string) => {
        langService.setCurrentLang(lang);
        langService.notifyListeners(lang);
        setSelectLangVisibility(false);
    };

    return (
        <div className="flex items-center md:order-2 space-x-1 md:space-x-0 rtl:space-x-reverse">
            <button
                type="button"
                onClick={() => setSelectLangVisibility(!isSelectLangVisible)}
                data-dropdown-toggle="language-dropdown-menu"
                className="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 rounded-lg cursor-pointer hover:bg-gray-100"
            >
                <CurrentLangIcon />
                {getCurrentLanguageTranslation(t, langService.currentLang).label}
            </button>
            <div
                className={`absolute right-6 top-14 z-50 ${
                    isSelectLangVisible ? "visible" : "hidden"
                } my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow`}
                id="language-dropdown-menu"
            >
                <ul className="py-2 font-medium" role="none">
                    {langService.langs.map(
                        (lang) =>
                            !langService.isCurrentLang(lang) && (
                                <li onClick={() => handleChangeLang(lang)} key={`li-language-selection-${lang}`}>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem"
                                    >
                                        <div className="inline-flex items-center">
                                            <CurrentLangIcon lang={lang} />
                                            {getCurrentLanguageTranslation(t, lang).label}
                                        </div>
                                    </a>
                                </li>
                            )
                    )}
                </ul>
            </div>
        </div>
    );
};

export default LangSelect;
