import { IBaseModel } from "../domain/models/BaseModel";

export class BaseModelImpl<T> implements IBaseModel<T> {
    assignData(data?: T) {
        if (!data) {
            return;
        }
        Object.assign(this, data);
    }
}
