import { useAuth } from "../../context/AuthProvider";
import LoadingPanel from "../shared/loading-panel/LoadingPanel";
import { Route, Routes } from "react-router-dom";
import UnAuthRoutes from "../../routes/UnauthenticatedRoutes";

function App() {
    const { loading } = useAuth();

    if (loading) {
        return <LoadingPanel isVisible={loading} />;
    }

    return (
        <Routes>
            <Route path="/*" element={<UnAuthRoutes />} />
        </Routes>
    );
}

export default App;
