import { useTranslation } from "react-i18next";
import { ExtendedField } from "../../../domain/models/dto/IncidentDTO";
import { isNil } from "lodash";
import { getExtendedSorted, getExtendedValue } from "../../../tools/ExtendedFieldTools";

interface ExtendedEnumFieldComponentProps<T> {
    className: string;
    field: T | ExtendedField<T>;
    titleKeyFactory: (value: T) => string;
}

function ExtendedEnumFieldComponent<T>({ className, field, titleKeyFactory }: ExtendedEnumFieldComponentProps<T>) {
    const { t } = useTranslation();
    const value = getExtendedValue(field);
    return (
        <td
            className={className + (getExtendedSorted(field) ? " text-gray-300" : "")}
            title={isNil(value) ? "" : value + " - " + t(titleKeyFactory(value))}
        >
            {isNil(value) ? "" : value + " - " + t(titleKeyFactory(value))}
        </td>
    );
}

export default ExtendedEnumFieldComponent;
