import { ExtendedField } from "../../../domain/models/dto/IncidentDTO";
import { isNil } from "lodash";
import { getExtendedSorted, getExtendedValue } from "../../../tools/ExtendedFieldTools";

interface ExtendedFieldComponentProps {
    className: string;
    field: number | string | ExtendedField<number | string>;
    prefix?: string;
}

function ExtendedFieldComponent({ className, field, prefix }: ExtendedFieldComponentProps) {
    const value = getExtendedValue(field);
    return (
        <td className={className + (getExtendedSorted(field) ? " text-gray-300" : "")}>
            {(isNil(value) ? <></> : value) + (prefix || "")}
        </td>
    );
}

export default ExtendedFieldComponent;
