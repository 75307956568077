export function getRandomNumber(maxExtremum: number, minExtremum: number = 0) {
    return (
        Math.floor(Math.random() * (maxExtremum - minExtremum)) + minExtremum
    );
}

export function getRandomFromArray<T>(array: T[]): T {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export function getRandomDate(start: Date, end: Date) {
    return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
}
