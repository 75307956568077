import { FC } from "react";
import { ExtendedIncident, Incident } from "../../../domain/models/Incident";
import { useTranslation } from "react-i18next";
import { IncidentFieldsEnum } from "../../../domain/enums/IncidentFieldsEnum";
import DeleteTrashIconSVG from "../../shared/svg/DeleteTrashIconSVG";
import EditPencilIconSVG from "../../shared/svg/EditPencilIconSVG";
import ExtendedEnumFieldComponent from "./ExtendedEnumFieldComponent";
import ExtendedFieldComponent from "./ExtendedFieldComponent";
import ExtendedCVSSComponent from "./ExtendedCVSSComponent";
import ExtendedCriticalityLevelComponent from "./ExtendedCriticalityLevelComponent";
import extendedIncidentMapperFactory from "../../../mappers/ExtendedIncidentMapper";
import IncidentDeletionOneRecordButton from "../IncidentDeletionOneRecordButton";

interface IncidentsTableRowProps {
    incident: Incident | ExtendedIncident;
    isExtendedQueue: boolean;
    updateIncidentHandler: (incident: Incident) => void;
    deleteIncidentHandler: (incident: Incident | ExtendedIncident) => void;
}

const IncidentsTableRow: FC<IncidentsTableRowProps> = (props) => {
    const { t } = useTranslation();

    const updateIncidentHandler = () => {
        const mapper = extendedIncidentMapperFactory();
        props.updateIncidentHandler(
            props.isExtendedQueue
                ? mapper.extendedToCommon(props.incident as ExtendedIncident)
                : (props.incident as Incident)
        );
    };

    return (
        <tr className="bg-white border-b hover:bg-gray-200">
            <td className="px-2 py-2 text-center border">{props.incident.order}</td>

            {props.incident.priority ? (
                <td className="px-6 py-2 text-center border">{props.incident.priority}</td>
            ) : (
                <></>
            )}

            <ExtendedCriticalityLevelComponent field={props.incident.criticalityLevel} />
            <ExtendedCVSSComponent field={props.incident.cvss} />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[125px] border"
                field={props.incident.stateLayer}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.STATE_LAYER}.subject.${value}`}
            />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[125px] border"
                field={props.incident.sector}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.SECTOR}.sector.${value}`}
            />

            <ExtendedFieldComponent
                className="px-2 py-1 text-center border"
                field={props.incident.loss}
                prefix={t("incident.LOSS.measurement")}
            />
            <ExtendedFieldComponent
                className="px-2 py-1 text-center border"
                field={props.incident.down}
                prefix={t("incident.DOWN.measurement")}
            />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[95px] border"
                field={props.incident.clientsAffected}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.CLIENTS_AFFECTED}.affected.${value}`}
            />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[95px] border"
                field={props.incident.resultOfExposure}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.RESULT_OF_EXPOSURE}.result.${value}`}
            />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[95px] border"
                field={props.incident.impactOnServices}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.IMPACT_ON_SERVICES}.impact.${value}`}
            />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[75px] border"
                field={props.incident.netAmount}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.NET_AMOUNT}.compromised.${value}`}
            />

            <ExtendedEnumFieldComponent
                className="px-2 py-1 truncate max-w-[75px] border"
                field={props.incident.affectedSystemType}
                titleKeyFactory={(value) => `incident.${IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE}.type.${value}`}
            />

            <td
                className="px-2 py-1 truncate text-center max-w-[95px] border"
                title={props.incident.dateTimeRegistrationFull}
            >
                {props.incident.dateTimeRegistrationFull}
            </td>
            <td
                className="px-2 py-2 truncate text-center max-w-[95px] border"
                title={props.incident.dateTimeDiscoveryFull}
            >
                {props.incident.dateTimeDiscoveryFull}
            </td>
            <td className="px-2 py-2 truncate text-center max-w-[95px] border" title={props.incident.dateTimeStartFull}>
                {props.incident.dateTimeStartFull}
            </td>
            <td className="px-2 py-2 text-center border">
                {props.incident.reportReliable ? t("incident.isReportReliable.yes") : t("incident.isReportReliable.no")}
            </td>

            <td
                className="px-2 py-2 text-center truncate max-w-[95px] border"
                title={t(`incident.${IncidentFieldsEnum.STATUS}.status.${props.incident.status}`)}
            >
                {t(`incident.${IncidentFieldsEnum.STATUS}.status.${props.incident.status}`)}
            </td>

            <td className="py-2 px-2 border">
                <IncidentDeletionOneRecordButton
                    incidentOrder={props.incident.order}
                    deleteHandler={() => props.deleteIncidentHandler(props.incident)}
                />
            </td>
            <td className="px-2 py-2 border">
                <div
                    onClick={updateIncidentHandler}
                    className="flex bg-green-600 hover:bg-green-700 items-center justify-center focus:ring-4 rounded-lg h-7 w-8"
                >
                    <EditPencilIconSVG iconColor="white" />
                </div>
            </td>
        </tr>
    );
};

export default IncidentsTableRow;
