import { Mapper } from "../domain/mappers/Mapper";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import UserModel from "../models/UserModel";
import { SimpleMapper } from "./SimpleMapper";

class UserMapper extends SimpleMapper<User, UserDTO> {
    protected entityConstructor = UserModel;

    protected fromDTOFields: string[] = [
        "firstName",
        "lastName"
    ];
}


export default function userMapperFactory(): Mapper<User, UserDTO> {
    return new UserMapper()
}
