import { Mapper } from "../domain/mappers/Mapper";
import * as _ from 'lodash';

export abstract class SimpleMapper<I, O> implements Mapper<I, O>{
    protected toDTOFields: string[] = [];
    protected fromDTOFields: string[] = [];
    protected entityConstructor: any;

    toDTO(attribute: I): O {
        return _.pick(attribute, this.toDTOFields || this.fromDTOFields) as any as O;
    }

    fromDTO(attribute: O): I {
        const values: I = _.pick(attribute, this.fromDTOFields || this.toDTOFields) as any as I;
        return this.entityConstructor
            ? new this.entityConstructor(values)
            : values;
    }
}
