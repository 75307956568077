import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import IncidentsPage from "../components/incidents/IncidentsPage";

interface UnAuthRoutesProps {}

const UnAuthRoutes: FC<UnAuthRoutesProps> = () => {
    return (
        <Routes>
            <Route path="/" element={<IncidentsPage />} />
        </Routes>
    );
};

export default UnAuthRoutes;
