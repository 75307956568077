import { emulationDays } from "../Config";
import { AffectedSystemTypeEnum } from "../domain/enums/AffectedSystemTypeEnum";
import { ClientsAffectedEnum } from "../domain/enums/ClientsAffectedEnum";
import { CriticalityLevelEnum } from "../domain/enums/CriticalityLevelEnum";
import { ImpactOnServicesEnum } from "../domain/enums/ImpactOnServicesEnum";
import { NetAmountEnum } from "../domain/enums/NetAmountEnum";
import { ResultOfExposureEnum } from "../domain/enums/ResultOfExposureEnum";
import { SectorEnum } from "../domain/enums/SectorEnum";
import { StateLayerEnum } from "../domain/enums/StateLayerEnum";
import { VerificationStatusEnum } from "../domain/enums/VerificationStatusEnum";
import {
    ExtendedIncidentCollection,
    Incident,
    IncidentCollection,
} from "../domain/models/Incident";
import {
    ExtendedIncidentDTO,
    IncidentDTO,
} from "../domain/models/dto/IncidentDTO";
import { IncidentService } from "../domain/services/IncidentService";
import extendedIncidentMapperFactory from "../mappers/ExtendedIncidentMapper";
import incidentMapperFactory from "../mappers/IncidentMapper";
import { addDaysToNow } from "../tools/DatetimeTools";
import { getRandomNumberOfEnum } from "../tools/EnumTools";
import {
    getRandomDate,
    getRandomFromArray,
    getRandomNumber,
} from "../tools/RandomTools";
import { RemoveResponse } from "../types/ApiResults";
import { incidentFields } from "../validation-schemas/IncidentSchema";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { v4 as uuidv4 } from "uuid";

export class IncidentServiceImpl
    extends BaseServiceImpl
    implements IncidentService
{
    async getIncidents(
        page: number,
        limit: number
    ): Promise<IncidentCollection> {
        try {
            const mapper = incidentMapperFactory();
            const result = await this.getCollection<IncidentDTO>(
                `/api/v1/incidents/?page=${page}&limit=${limit}`,
                []
            );
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("IncidentServiceImpl.getIncidents");
            console.log(err);
        }
    }

    async getQueue(page: number, limit: number): Promise<IncidentCollection> {
        try {
            const mapper = incidentMapperFactory();
            const result = await this.getCollection<IncidentDTO>(
                `/api/v1/queue/?page=${page}&limit=${limit}`,
                []
            );
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("IncidentServiceImpl.getQueue");
            console.log(err);
        }
    }

    async getExtendedQueue(
        page: number,
        limit: number
    ): Promise<ExtendedIncidentCollection> {
        try {
            const mapper = extendedIncidentMapperFactory();
            const result = await this.getCollection<ExtendedIncidentDTO>(
                `/api/v1/queue/extended?page=${page}&limit=${limit}`,
                []
            );
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("IncidentServiceImpl.getExtendedQueue");
            console.log(err);
        }
    }

    async createIncident(incident: Incident): Promise<Incident> {
        try {
            const mapper = incidentMapperFactory();
            return this.post("/api/v1/incidents", mapper.toDTO(incident));
        } catch (err) {
            console.log("IncidentServiceImpl.createIncident");
            console.log(err);
        }
    }

    async updateIncident(incident: Incident): Promise<Incident> {
        try {
            if (!incident?.id) {
                return null;
            }
            const mapper = incidentMapperFactory();
            return this.patch(
                `/api/v1/incidents/${incident.id}`,
                mapper.toDTO(incident)
            );
        } catch (err) {
            console.log("IncidentServiceImpl.updateIncident");
            console.log(err);
        }
    }

    async deleteIncident(incidentId: string): Promise<RemoveResponse> {
        try {
            if (!incidentId) {
                return { status: false };
            }
            return this.delete(`/api/v1/incidents/${incidentId}`);
        } catch (err) {
            console.log("IncidentServiceImpl.deleteIncident");
            console.log(err);
        }
    }

    createMockIncident(): Partial<Incident> {
        const mapper = incidentMapperFactory();
        return mapper.fromDTO({
            id: uuidv4(),
            reportReliable: Boolean(getRandomFromArray([1, 1, 1, 1, 0])),
            status: getRandomFromArray([
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IS_PRIMARY,
                VerificationStatusEnum.IN_PROCESS,
                VerificationStatusEnum.COMPLETED,
                VerificationStatusEnum.PROCESSED_BY_CERT,
                VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM,
                VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM,
            ]),
            criticalityLevel: getRandomNumberOfEnum(CriticalityLevelEnum),
            cvss: getRandomNumber(incidentFields.cvss.max),
            stateLayer: getRandomNumberOfEnum(StateLayerEnum),
            sector: getRandomNumberOfEnum(SectorEnum),
            loss: getRandomNumber(incidentFields.loss.max),
            down: getRandomNumber(incidentFields.down.max),
            clientsAffected: getRandomNumberOfEnum(ClientsAffectedEnum),
            resultOfExposure: getRandomNumberOfEnum(ResultOfExposureEnum),
            dateTimeDiscovery: getRandomDate(
                new Date(),
                addDaysToNow(emulationDays)
            ).toISOString(),
            dateTimeStart: getRandomDate(
                new Date(),
                addDaysToNow(emulationDays)
            ).toISOString(),
            impactOnServices: getRandomNumberOfEnum(ImpactOnServicesEnum),
            netAmount: getRandomNumberOfEnum(NetAmountEnum),
            affectedSystemType: getRandomNumberOfEnum(AffectedSystemTypeEnum),
            dateTimeRegistration: getRandomDate(
                new Date(),
                addDaysToNow(emulationDays)
            ).toISOString(),
            dateCreated: getRandomDate(
                new Date(),
                addDaysToNow(emulationDays)
            ).toISOString(),
            dateModified: getRandomDate(
                new Date(),
                addDaysToNow(emulationDays)
            ).toISOString(),
        });
    }

    async deleteAllIncidents(): Promise<RemoveResponse> {
        try {
            return this.delete(`/api/v1/incidents/`);
        } catch (err) {
            console.log("IncidentServiceImpl.deleteAllIncidents");
            console.log(err);
        }
    }

    async createIncidentsFromCSV(file: File): Promise<Incident[]> {
        try {
            return this.sendFile(file, "/api/v1/incidents/csv");
        } catch (err) {
            console.log("IncidentServiceImpl.createIncidentsFromCSV");
            console.log(err);
        }
    }
}

export function incidentServiceFactory(): IncidentService {
    return new IncidentServiceImpl();
}
