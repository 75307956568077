import { ErrorService } from "../domain/services/ErrorService";

export class ErrorServiceImpl implements ErrorService {

    async throwResponseError(response: any) {
        const responseJSON = await response.json();
        throw new Error(
            JSON.stringify({
                statusText: response.statusText,
                data: responseJSON,
            })
        );
    }
}

export function ErrorServiceFactory(): ErrorService {
    return new ErrorServiceImpl();
}
