import { FC } from "react";

const UaLangIconSVG: FC = () => {
    return (
        <svg className="rounded-xl me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_301_153)">
                <path d="M0 0H20V19.9996H0V0Z" fill="#FFDA44" />
                <path d="M0 0H20V9.99979H0V0Z" fill="#338AF3" />
            </g>
            <defs>
                <clipPath id="clip0_301_153">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UaLangIconSVG;
