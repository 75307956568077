export enum IncidentFieldsEnum {
    ORDER = "ORDER",
    PRIORITY = "PRIORITY",
    CRITICALITY_LEVEL = "CRY",
    CVSS = "CVSS",
    STATE_LAYER = "SL",
    SECTOR = "SEC",
    LOSS = "LOSS",
    DOWN = "DOWN",
    CLIENTS_AFFECTED = "CA",
    RESULT_OF_EXPOSURE = "RE",
    IMPACT_ON_SERVICES = "IS",
    NET_AMOUNT = "NET",
    AFFECTED_SYSTEM_TYPE = "AST",
    DATE_TIME_REGISTRATION = "RTD",
    DATE_TIME_DISCOVERY = "DTD",
    DATE_TIME_START = "STD",
    REPORT_RELIABLE = "RPT",
    STATUS = "STATUS",
    ID = "ID",
}
