import {
    MixedSchema,
    NumberSchema,
    ObjectShape,
    StringSchema,
    BooleanSchema,
    mixed,
    number,
    object,
    string,
    boolean,
} from "yup";
import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";

export function schemaFactory<T extends Object>(
    t: TFunction,
    fields: Fields<T>
) {
    return object().shape(
        Object.entries(fields).reduce((acc, [key, value]) => {
            let schema:
                | StringSchema
                | NumberSchema
                | BooleanSchema
                | MixedSchema<any> = string();
            if (value.type === "number") {
                schema = number();
            } else if (value.type === "select") {
                schema = mixed().oneOf(
                    value.selectOptions?.map((option) => option.value) ?? [],
                    t("errors.validation.invalidOption")
                );
            } else if (value.type === "boolean") {
                schema = boolean();
            }

            if (
                schema instanceof StringSchema ||
                schema instanceof NumberSchema
            ) {
                schema = schema
                    .min(value.min, t("errors.validation.min") + value.min)
                    .max(value.max, t("errors.validation.max") + value.max);
            }

            acc[key] = schema.required(t("errors.validation.required"));
            return acc;
        }, {} as ObjectShape)
    );
}
