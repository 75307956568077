import { AffectedSystemTypeEnum } from "../domain/enums/AffectedSystemTypeEnum";
import { ClientsAffectedEnum } from "../domain/enums/ClientsAffectedEnum";
import { CriticalityLevelEnum } from "../domain/enums/CriticalityLevelEnum";
import { ImpactOnServicesEnum } from "../domain/enums/ImpactOnServicesEnum";
import { NetAmountEnum } from "../domain/enums/NetAmountEnum";
import { ResultOfExposureEnum } from "../domain/enums/ResultOfExposureEnum";
import { SectorEnum } from "../domain/enums/SectorEnum";
import { StateLayerEnum } from "../domain/enums/StateLayerEnum";
import { VerificationStatusEnum } from "../domain/enums/VerificationStatusEnum";
import {
    ExtendedField,
    ExtendedIncidentDTO,
} from "../domain/models/dto/IncidentDTO";
import { ExtendedIncident } from "../domain/models/Incident";
import { getFullDateFormat, getShortDateFormat } from "../tools/DatetimeTools";
import { BaseModelImpl } from "./BaseModelImpl";

export class ExtendedIncidentModel
    extends BaseModelImpl<ExtendedIncidentDTO>
    implements ExtendedIncident
{
    constructor(data?: ExtendedIncidentDTO) {
        super();
        this.assignData(data);
    }

    id?: string;
    order?: number;
    priority?: number;
    reportReliable: boolean;
    status: VerificationStatusEnum;
    dateTimeDiscovery: string;
    dateTimeStart: string;
    dateTimeRegistration: string;
    dateCreated?: string;
    dateModified?: string;

    criticalityLevel: ExtendedField<CriticalityLevelEnum>;
    cvss: ExtendedField<number>;
    stateLayer: ExtendedField<StateLayerEnum>;
    sector: ExtendedField<SectorEnum>;
    loss: ExtendedField<number>;
    down: ExtendedField<number>;
    clientsAffected: ExtendedField<ClientsAffectedEnum>;
    resultOfExposure: ExtendedField<ResultOfExposureEnum>;
    impactOnServices: ExtendedField<ImpactOnServicesEnum>;
    netAmount: ExtendedField<NetAmountEnum>;
    affectedSystemType: ExtendedField<AffectedSystemTypeEnum>;

    get dateTimeDiscoveryShort() {
        return getShortDateFormat(this.dateTimeDiscovery);
    }

    get dateTimeDiscoveryFull() {
        return getFullDateFormat(this.dateTimeDiscovery);
    }

    get dateTimeStartShort() {
        return getShortDateFormat(this.dateTimeStart);
    }

    get dateTimeStartFull() {
        return getFullDateFormat(this.dateTimeStart);
    }

    get dateTimeRegistrationShort() {
        return getShortDateFormat(this.dateTimeRegistration);
    }

    get dateTimeRegistrationFull() {
        return getFullDateFormat(this.dateTimeRegistration);
    }
}
