import { FC } from "react";
import CustomDropdown from "../shared/form-inputs/CustomDropdown";
import IncidentCreationModalForm from "./IncidentCreationModalForm";
import IncidentsCSVImportButton from "./IncidentsCSVImportButton";
import IncidentsDeletionButton from "./IncidentsDeletionButton";
import IncidentsEmulationMenu from "./IncidentsEmulationMenu";
import IncidentsQueueSortingMenu from "./IncidentsQueueSortingMenu";
import IncidentsTableTabulation from "./IncidentsTableTabulation";
import { Incident } from "../../domain/models/Incident";
import { IncidentDTO } from "../../domain/models/dto/IncidentDTO";
import { enumToSelectProps, enumValueToSelectProps } from "../../tools/EnumTools";
import { PaginationEnum } from "../../domain/enums/common/PaginationEnum";
import { SelectValue } from "../../types/SelectProps";
import { TabsEnum } from "../../domain/enums/TabsEnum";
import { useTranslation } from "react-i18next";

interface IncidentsTableManagementProps {
    errorCSVImportCallback: () => void;
    successCSVImportCallback: () => void;
    resetSelectedIncident: () => void;
    selectedIncident: Incident;
    createOrUpdateIncident: (values: IncidentDTO) => Promise<void>;
    setLoading: (value: boolean) => void;
    successEmulationCallback: () => void;
    errorEmulationCallback: () => void;
    updateQueueCallback: () => void;
    deleteAllIncidents: () => Promise<void>;
    limitHandler: (value: SelectValue) => void;
    tabName: TabsEnum;
    setTabName: (value: TabsEnum) => void;
    extendedEnable: boolean;
    setExtendedEnable: (value: boolean) => void;
}

const IncidentsTableManagement: FC<IncidentsTableManagementProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="bg-gray-100 w-[95%] mr-auto mb-[10px] py-2 px-3 ml-auto flex items-center justify-between shadow-md rounded-xl">
            <div className="flex items-center">
                <div className="pr-4 border-r-[1px] border-gray-300">
                    <IncidentCreationModalForm
                        resetSelectedIncident={props.resetSelectedIncident}
                        selectedIncident={props.selectedIncident}
                        createOrUpdateIncident={props.createOrUpdateIncident}
                    />
                </div>
                <div className="flex items-center justify-center pr-2 border-r-[1px] border-gray-300">
                    <h3 className="ml-4 text-[16px] font-semibold text-gray-500">
                        {t("incident.csv.importCSVFileShort")}
                    </h3>
                    <IncidentsCSVImportButton
                        successCallback={props.successCSVImportCallback}
                        errorCallback={props.errorCSVImportCallback}
                        setLoading={props.setLoading}
                    />
                </div>
                <IncidentsEmulationMenu
                    successEmulationCallback={props.successEmulationCallback}
                    errorEmulationCallback={props.errorEmulationCallback}
                />
                <div className="flex items-center pr-4 border-r-[1px] border-gray-300">
                    <h3 className="mr-2 ml-4 text-[16px] font-semibold text-gray-500">{t("pagination.show")}</h3>
                    <CustomDropdown
                        defaultValue={enumValueToSelectProps(PaginationEnum.SHOW_20, "pagination")}
                        data={enumToSelectProps(PaginationEnum, "pagination")}
                        dropDownCallback={props.limitHandler}
                    />
                </div>
                <IncidentsQueueSortingMenu
                    extendedEnable={props.extendedEnable}
                    setExtendedEnable={props.setExtendedEnable}
                    updateQueueCallback={props.updateQueueCallback}
                />
                <IncidentsDeletionButton deleteHandler={props.deleteAllIncidents} />
            </div>
            <IncidentsTableTabulation tabName={props.tabName} setTabName={props.setTabName} />
        </div>
    );
};

export default IncidentsTableManagement;
