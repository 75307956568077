import { FC } from "react";
import Popup from "reactjs-popup";
import "./LoadingPanel.css";

interface LoadingPanelProps {
    isVisible: boolean;
}

const LoadingPanel: FC<LoadingPanelProps> = (props) => {
    return (
        <Popup
            open={props.isVisible}
            modal
            overlayStyle={{ zIndex: 999999 }}
            closeOnDocumentClick={false}
            closeOnEscape={false}
        >
            <div className="loader__wrapper">
                <div className="loader"></div>
            </div>
        </Popup>
    );
};

export default LoadingPanel;
